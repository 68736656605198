
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { toast } from 'react-toastify';
import api from '../../services/api.js';
import moment from "moment"
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Button } from '@material-ui/core/';
import { LinearProgress } from '@material-ui/core';
import {
    Select,
    MenuItem,
    InputLabel
} from "@material-ui/core";

function Row3(props) {
    const { columns, reunioes, open} = props;
    const [open2, setOpen2] = React.useState(false);
    const [reunioesPorCliente, setReunioesPorCliente] = React.useState([]);

    useEffect(() => {
        let reunioesPorCliente = [];
        reunioes.map((reuniao)=> {
            if(reunioesPorCliente[reuniao["customer"]]) {
                reunioesPorCliente[reuniao["customer"]].push(reuniao)
            }else{
                reunioesPorCliente[reuniao["customer"]] = []
                reunioesPorCliente[reuniao["customer"]].push(reuniao)
            }
        })
        let reunioesPorClienteTratada = [];
        for(var customer in reunioesPorCliente) {
            reunioesPorClienteTratada.push({
                "name": customer,
                "reunioes": reunioesPorCliente[customer]
            })
        }
        setReunioesPorCliente(reunioesPorClienteTratada);

    },[])

    return ( 
    <>
    {reunioesPorCliente.map((customer) => {
        return <TableRow colSpan={columns.length} style={{display: open ? "" : "none"}}>
            <TableCell  align="center"  ></TableCell>
            <TableCell  align="center" style={{width: 150}} width={150}></TableCell>
            <TableCell  align="center" style={{width: 180}}></TableCell>
            <TableCell  align="center" style={{width: 150}} width={150}>{customer["name"]}</TableCell>
            {columns.map((data,) => {
                let count = 0;
                customer["reunioes"].map((value) => {
                    if(data == value.data_reuniao){
                        count++;
                    }
                })
                return <TableCell align="center" style={{ width: 160 }}> { count > 0 ? <Badge badgeContent={count} color="primary"></Badge> : 0 }</TableCell>
            })}
            <TableCell align="center">{reunioes[name]?.length > 0 ? <Badge badgeContent={customer["reunioes"].length} color="primary" style={{fontSize: 18}}></Badge> : 0 }</TableCell>
        </TableRow>

    })}
    </>

    )

}

function Row2(props) {
    const { columns,reunioes, reuniaoStatus,name, open} = props;
    const [open2, setOpen2] = React.useState(false);


 
    return <>
        <TableRow style={{display: open ? "" : "none", backgroundColor: "#FAFAFA"}}>
            <TableCell>
                <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen2(!open2)}
                >
                {open2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            <TableCell  align="center" style={{width: 150}} width={150}></TableCell>
            <TableCell  align="center" style={{width: 180, fontWeight: "bold"}}>{reuniaoStatus["status"]}</TableCell>
            <TableCell  align="center" style={{width: 150}} width={150}></TableCell>
            {columns.map((data,) => {
                    let count = 0;
                    reuniaoStatus["reunioes"].map((value) => {
                        if(data == value.data_reuniao){
                            count++;
                        }
                    })

                    return <TableCell align="center" style={{ width: 160 }}> { count > 0 ? <Badge badgeContent={count} color="primary"></Badge> : 0 }</TableCell>
                })}
                <TableCell align="center">{reunioes[name]?.length > 0 ? <Badge badgeContent={reuniaoStatus["reunioes"].length} color="primary" style={{fontSize: 18}}></Badge> : 0 }</TableCell>
        </TableRow>
        <Row3 columns={columns} reunioes={reuniaoStatus["reunioes"]} open={open2}></Row3>
    </>

}

function Row(props) {
    const { row, name, columns, reunioes, reunioesPorStatus} = props;
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {/* <TableCell component="th" scope="row">
            {row.name}
          </TableCell> */}
          <TableCell align="center" style={{width: 150}} width={150}>{name}</TableCell>
          
          <TableCell align="center" style={{width: 140}} width={140}></TableCell>
          <TableCell align="center" style={{width: 140}} width={140}></TableCell>

            {columns.map((data,) => {
                 let count = 0;
                 row.map((value) => {
                    // console.log(data == value.data_reuniao,data,value.data_reuniao,name)
                    if(data == value.data_reuniao){
                        count = value.count;
                    }
                })

                return <TableCell align="center"> { count > 0 ? <Badge badgeContent={count} color="primary" style={{backgroundColor:"#4B8B33"}}></Badge> : 0 }</TableCell>
            })}
            <TableCell align="center">{reunioes[name]?.length > 0 ? <Badge badgeContent={reunioes[name].length} color="primary" style={{fontSize: 18}}></Badge> : 0 }</TableCell>
        </TableRow>
        
        {reunioesPorStatus && reunioesPorStatus?.map((reuniaoStatus)=> (
            <Row2 open={open} reuniaoStatus={reuniaoStatus} columns={columns} reunioes={reunioes} name={name}></Row2>
         ))}  
        

      </React.Fragment>
    );
}

const Kpi = (props) => {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [reunioes, setReunioes] = useState([]);
    const [reunioesPorStatus, setReunioesPorStatus] = useState([]);

    const [customers, setCustomers] = React.useState([]);
    const [customersSelected, setCustomersSelect] = React.useState(true);
    const [assessorSelect, setAssessorSelect] = useState([]);
    const [assessores, setAssessores] = useState([]);
    const [campoData, setCampoData] = useState("data_reuniao");

    const [dataInicio, setDataInicio] = React.useState("");
    const [dataFim, setDataFim] = React.useState("");
    const [loading, setLoading] = useState(false);

    const getKpi = async(params) => {
        try {
            setLoading(true)

            let url = "agenda-export-kpi";
            let result = await api.get(url,{
                params: params
            });
            
            setRows(result.data.rows);
            setColumns(result.data.columnsData);
            setReunioes(result.data.reunioes);
            setReunioesPorStatus(result.data.reunioesPorStatus)
            setLoading(false)
        } catch(exception) {
            console.log(exception)
            setLoading(false)
        }
    }

    useEffect(async () => {
        try {
            // getKpi()
        } catch(Ex) {
            toast.error(Ex.message);
        }
    }, []);


    useEffect(() => {
        (async () => {
          const result = await api.get("customers",{ 
            params: { 
              "assessor_id": null
            }
          });
          let data = result.data;
          setCustomers(data.data);
        })();
    }, []);

    const gerar = () => {

        if(!dataInicio) {
            toast.error("Data Início é obrigatório")
            return
        }

        if(!dataFim) {
            toast.error("Data Fim é obrigatório")
            return
        }


        if(dataInicio >= dataFim) {
            toast.error("Data início deve ser menor que Data Fim")
        }

        let dados = {
            data_inicio : dataInicio,
            data_fim : dataFim,
            cliente_id : customersSelected?.id ? customersSelected?.id : null,
            assessor_id : assessorSelect?.id ? assessorSelect?.id : null,
            campo_data : campoData
        }

        getKpi(dados)

    }

    useEffect(() => {
        (async () => {
            try {
                const result = await api.get("assessores");
                let data = result.data?.data;
                setAssessores(data);
            } catch (e) {
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.message ? data.message : "Ocorreu um erro ao capturar os dados do mailing";
                toast.error(msg)
            }
        })();
    }, []);

    return (<>
    <div>
            <h1> { "KPIs" } </h1>
            </div>
            <div
                style={{
                borderRadius: "5px",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                backgroundColor:"#fff",
                padding:"10px"
                }}
            >
            <Grid container  spacing={2}>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="Assessor"
                        options={assessores}
                        onChange={(event, newInputValue) => {
                            setAssessorSelect(newInputValue);
                        }}
                        value={assessorSelect}
                        getOptionLabel={(option) => option.name || ""}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Assessor" />}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="Cliente"
                        options={customers}
                        onChange={(event, newInputValue) => {
                            setCustomersSelect(newInputValue);
                        }}
                        value={customersSelected}
                        getOptionLabel={(option) => option.name || ""}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Cliente" />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop: "15px"}}>
            <Grid container spacing={2} style={{marginTop: "15px"}}>
                <Grid item xs={4}>
                    <TextField  
                        style={{marginLeft: "5px"}}
                        id="data_inicio"
                        label="Data Início"
                        InputLabelProps={{
                        shrink: true,
                        }}
                        value={dataInicio} 
                        type={"date"}
                        onChange={
                        evt => setDataInicio(evt.target.value)
                        } fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <TextField  
                        style={{marginLeft: "5px"}}
                        id="data_fim"
                        label="Data Fim"
                        InputLabelProps={{
                        shrink: true,
                        }}
                        value={dataFim} 
                        type={"date"}
                        onChange={
                        evt => setDataFim(evt.target.value)
                        } fullWidth/>
                </Grid>
                <Grid item xs={4}>

                    <InputLabel>Campo Data</InputLabel>
                    <Select
                        labelId="Campo Data"
                        id="campo_data"
                        value={campoData}
                        onChange={evt => setCampoData(evt.target.value)}
                    >
                        <MenuItem value={"data_reuniao"}>Data Reunião</MenuItem>
                        <MenuItem value={"data_agendamento_assessor"}>Data Agendamento</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            </Grid>
            <Grid container style={{marginTop:"15px"}}  spacing={2}>
            <Grid item xs={5}>
                <Button 
                variant="contained" 
                style={{color:"#4B8B33"}} 
                onClick={() => { gerar() }}
                href="#contained-buttons"
                disabled={loading}
                >
                GERAR
                </Button>
            </Grid>
            </Grid>     
        </div>
        {loading && <div style={{width: "100%"}}>
                <LinearProgress/>
            </div>}
                
        <br />
        {rows.length > 0 ? 
        <TableContainer component={Paper} style={{maxHeight: 440}}>
            <Table stickyHeader aria-label="collapsible table">
                <TableHead>
                <TableRow>
                    <TableCell />
                    <TableCell align="center" style={{fontWeight: "bold", width: 150}}    width="150">Assesor</TableCell>
                    <TableCell align="center" style={{fontWeight: "bold", width: 140}}      width="140">Status</TableCell>
                    <TableCell align="center" style={{fontWeight: "bold", width: 140}}      width="140">Cliente</TableCell>
                    {columns.map((data) => (
                        <TableCell style={{fontWeight: "bold"}} align="center">{moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")}</TableCell>
                    ))}
                    <TableCell style={{fontWeight: "bold"}} align="center">Total</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row, key) => (
                    // console.log(row)
                    <Row key={row[0].name} name={row[0].name} row={row} reunioes={reunioes} reunioesPorStatus={reunioesPorStatus[row[0].name]} columns={columns} />
                ))}
                </TableBody>
            </Table>
        </TableContainer> : ""
        }
    </>);

}

export default Kpi;