import React, { useState,useMemo, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import {TextField, Button} from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { DataGrid, 
    ptBR, 
    nlNL,
    GridToolbar, 
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    getGridNumericColumnOperators 
} from '@mui/x-data-grid';


import Paper from '@material-ui/core/Paper';

import {StyledTableCell, useStyles} from "./styles";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/Add';
import {randomId} from '@mui/x-data-grid-generator';
import {Link, useParams, useHistory} from "react-router-dom";
import { StyledTableRow } from '../../users/ListingUser/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '@material-ui/lab/Pagination';
import { toast  } from 'react-toastify';
import api from '../../../services/api';
import * as axios from 'axios';
import { LinearProgress } from '@material-ui/core';


function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  }


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const FormCustomer = (props) => {
    const classes = useStyles();
    const mapPageToNextCursor = React.useRef({});

    let { id } = useParams();
     
    const [active,setActive] = useState(false)
    const [name,setName] = useState("")
    const [description,setDescription] = useState("")
    const [leadIDs, setLeadIDs] = useState([]);
  
    const [leadsAvailable, setLeadsAvailable] = useState([]);
    const [leads, setLeads] = useState([]);

    const history = useHistory()
    const [loading, setLoading] = React.useState(true);

    const [valueTab, setValueTab] = React.useState(id ? 0 : 1);
    const [pageSize, setPageSize] = React.useState(100);
    const [page, setPage] = React.useState(0);
    const [nextPage, setNextPage] = React.useState(0);

    const [rowCount, setRowCount] = React.useState(0);
    const [filters, setFilters] = React.useState(1);
    const [deletedRows, setDeletedRows] = useState([]);

    
    
    const handleTabChange = (event, newValue) => {
        setValueTab(newValue);
    };

    useEffect(() => {
        (async () => {

            if(id) {
               await api.get(`mailing/${id}`).then(response => {
                    const data = response?.data
                
                    setActive(data.active)
                    setName(data.name)
                    setDescription(data.description)
                    setLeads(data.leads);
    
                    const leadIDs = data.leads.map(function(lead){
                        return lead.id
                    })
                    
                    setLeadIDs(leadIDs)
                })
            }

        })()
    },[]);
    
    useEffect(() => {
        (async () => {
         

            try {
                setLoading(true)
                let result = await api.get(`leads`,
                    {
                        params: {
                            page: page+1,
                            filters:filters,
                            id_mailling: id
                        }
                    }
                ).then(response => {
                    let data = response?.data
                    setRowCount(data.total);
                    setLeadsAvailable(data.data)
                    setLoading(false)
                 })
         
        
                
            }catch(e){
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.message ? data.message : "Ocorreu um erro ao capturar os dados do mailing";
                toast.error(msg)
            }
          
          })()
    },[page,filters]);

    const handleActive = (event) => {
        setActive(event.target.checked)
    };


    const handleName = (event) => {
        setName(event.target.value)
    };


    const handleDescription = (event) => {
        setDescription(event.target.value)
    };
    const handlePage = (newPage) => {
        setPage(newPage)
    };

    const onFilterChange = (filterModel) => {
        console.log(filterModel)
        setFilters(filterModel.items)
    };
    

    const handlePurge = async () => {

        if(id){
            await api.patch(`/mailing/${id}/remover-leads`,deletedRows)
          
            console.log("removendo..")
            console.log(deletedRows)
            console.log(leads.filter((r) => deletedRows.filter((id) => r.id === id).length < 1))
            
            setLeads(
                leads.filter((r) => deletedRows.filter((id) => r.id === id).length < 1)
            );

            toast.success("Leads Removidos com sucesso!")
        }
   
    };
   

    const handleSave = async () => { 

        let data = {
            active,
            name,
            description,
            lead_ids: leadIDs
        };
       
        try {
            if(id){
                await api.patch(`/mailing/${id}/save`,data)
                toast.success("Mailing editado com sucesso")
                
            } else {
                const result = await api.post("/mailing/save",data)
                toast.success("Mailing cadatrado com sucesso")
                const id_created = (result.data?.data?.id)
                history.replace(`/mailing/${id_created}/edit`)
            }
          
        }catch(e){
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.message ? data.message : "Ocorreu um erro ao salvar o mailing";
            toast.error(msg)
        }
    };
  
    const columns = [
        { 
            field: 'id', 
            headerName: 'ID', 
            width: 100,
            filterable: false,
            hide: true
        },
        { 
            field: 'site', 
            headerName: 'Site', 
            width: 100,
            filterable: false,
            hide: true
        },
        { 
            field: 'telephone', 
            headerName: 'Telefone', 
            width: 100,
            filterable: false,
            hide: true
        },
        { 
            field: 'cnpj', 
            headerName: 'CNPJ', 
            width: 150,
            filterable: true,
            hide: true
        },
        {
          field: 'corporate_name',
          headerName: 'Razão Social',
          width: 250,
          filterable: true,
          sortable: true,
        },
        {
          field: 'cnae',
          headerName: 'CNAE',
          width: 250,
          filterable: true,
          sortable: true,
        },
        {
          field: 'revenues',
          headerName: 'Faturamento',
          sortable: true,
          width: 250,
          filterable: true,
          type: "number"
        },
        {
            field: 'number_employees',
            headerName: 'Nº Funcionários',
            sortable: true,
            width: 250,
            filterable: true,
            type: "number"
        },
        { 
            field: 'logradouro', 
            headerName: 'logradouro', 
            width: 100,
            filterable: false,
            hide: true
        },
        { 
            field: 'neighborhood', 
            headerName: 'Bairro', 
            width: 100,
            filterable: false,
            hide: true
        },
        { 
            field: 'number', 
            headerName: 'Número', 
            width: 100,
            filterable: false,
            hide: true
        },
        {
            field: 'state',
            headerName: 'UF',
            sortable: true,
            width: 250,
            filterable: true,
        },
        { 
            field: 'zip_code', 
            headerName: 'CEP', 
            width: 100,
            filterable: false,
            hide: true
        },
        {
            field: 'city',
            headerName: 'Cidade',
            sortable: true,
            width: 250,
            filterable: true,
        },
      ];
      

    const downloadLeads = async () => {
        try {
            setLoading(true);
            
            await axios({
                url: `${api.defaults.baseURL}lead-export?mailing_id=${id}&include_contacts=true`,
                method: 'GET',
                responseType: 'blob', // importante
            }).then((response) => {
                // cria link para o arquivo na memória do navegador
                const href = URL.createObjectURL(response.data);
            
                // cria elemento "a" HTML com href para o arquivo e clica
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `leads-mailing-${id}.xlsx`); // nome do arquivo
                document.body.appendChild(link);
                link.click();
                
                // limpa o elemento "a" e remove o ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                
                setLoading(false);
                toast.success("Download de leads concluído com sucesso!");
            });
        } catch (error) {
            setLoading(false);
            toast.error("Erro ao baixar os leads");
            console.error(error);
        }
    };

    return (
   
        <>
         
            <Container fixed>
                <div>
                    <h1> {!id ? "Novo Mailing" : "Editar Mailing" } </h1>
                </div>
                <div  style={{backgroundColor:"#ffff",padding:10}}>
                <form  noValidate autoComplete="off">
                    
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<GreenCheckbox checked={active} onChange={handleActive} name="active" />}
                                label="Ativo"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required id="standard-required"  label="Nome" value={name} onChange={handleName} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField  id="standard-required"  label="Descricao" value={description} onChange={handleDescription} fullWidth />
                        </Grid>
                      
                    </Grid>
      

                    <Paper className={classes.root}>
                        <Tabs
                            value={valueTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Leads vinculados"/> 
                            <Tab label="Adicionar Leads" />
    
                        </Tabs>
                    </Paper>

                    <TabPanel value={valueTab} index={0}>
                        {id && 
                            <Grid container className={classes.root} style={{paddingTop: "30px"}} spacing={2}>
                                <Grid item xs={12}>
                                    <label style={{color:"rgba(0, 0, 0, 0.54)",fontSize:"16px"}}>Leads Vinculados</label>
                                    <br />
                                    <Grid style={{display:"flex" }}>
                                        <Grid item xs={3} style={{marginRight:"-35px"}}>
                                            <Button variant="contained" color="primary" style={{fontSize:"12px"}} onClick={() => handlePurge()}>
                                                REMOVER LEADS SELECIONADOS
                                            </Button>
                                        
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button 
                                                variant="contained" 
                                                style={{backgroundColor: "#07bc0c", color: "white", marginLeft: "10px", fontSize:"12px"}}
                                                onClick={downloadLeads}
                                            >
                                                BAIXAR LEADS
                                            </Button>
                                        </Grid>
                                    </Grid> 
                                    
                                    <div style={{ height: 450, width: '100%', paddingTop: "10px" }}>
                                        <DataGrid
                                            rows={leads}
                                            checkboxSelection
                                            columns={columns}
                                            pageSize={25}
                                            rowsPerPageOptions={[10]}
                                            localeText={ptBR.props.MuiDataGrid.localeText}
                                            loading={loading}
                                            components={{ Toolbar: CustomToolbar }} 
                                            selectionModel={deletedRows}
                                            onSelectionModelChange={(ids) => {
                                                setDeletedRows(ids)
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        }  
                    </TabPanel>
                    <TabPanel value={valueTab} index={1}>
                        <div style={{height: 400, width: '100%', marginTop: 60, marginBottom: 60}}>
                            <DataGrid
                                rows={leadsAvailable}
                                rowCount={rowCount}
                                columns={columns}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                onPageChange={handlePage}
                                page={page}
                                pagination
                                paginationMode="server"
                                filterMode="server"
                                onFilterModelChange={onFilterChange}
                                rowsPerPageOptions={[pageSize]}
                                checkboxSelection
                                disableSelectionOnClick
                                localeText={ptBR.props.MuiDataGrid.localeText}
                                loading={loading}
                                selectionModel={leadIDs}
                                components={{ Toolbar: CustomToolbar }} 
                                onSelectionModelChange={(ids) => {
                                    setLeadIDs(ids)
                                }}
                                keepNonExistentRowsSelected     

                            />
                        </div>
                    </TabPanel>
                    <Grid container className={classes.root} style={{paddingTop: "30px"}} spacing={2}>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary"  onClick={handleSave}>
                                SALVAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                </div>
            </Container>
        </>
    )
}


export  default FormCustomer;