import React from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {TextField} from '@material-ui/core/';
import InputMask from 'react-input-mask';

const defaultMaskOptions = {
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 14, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

const CurrencyInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })

  return <MaskedInput class="MuiInputBase-input MuiInput-input" mask={currencyMask} {...inputProps} />

    // return <InputMask mask={currencyMask} {...inputProps}>
    //     {(inputProps) => <TextField {...inputProps} required  label="Faturamento" />}
    // </InputMask>
}



export default CurrencyInput
