import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import TableFooter from '@material-ui/core/TableFooter';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import {StyledTableCell,StyledTableRow, useStyles} from './styles.js'
import Container from '@material-ui/core/Container';
import { Grid, TextField} from "@material-ui/core";

import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import api from '../../../services/api.js';
import { LinearProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import moment from "moment"
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

function createData(corporate_name, cnpj) {
  return { corporate_name, cnpj };
}


export default function ListingCustomer() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [permissisao,setPermissisao] = useState(JSON.parse(localStorage.getItem("mailing")));
  const [totalRows, setTotalRows] = useState(0);
  const [confirmOpen,setConfirmOpen] = useState(false);
  const [deletedID,setDeletedID] = useState(null);
  const [corporateName,setCorporateName] = useState("")
  const [name,setName] = useState("")


  const handleCorporateName = (value) => {
    setCorporateName(value)
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    (async () => {
      await search();
    })()
  },[page])

  const search = async() => {
    try {
      setLoading(true)
      const result = await api.get(`mailings?page=${page}&pageSize=25&corporate_name=${corporateName}&name=${name}`)
      const data = result?.data?.data
      setRows(data)
      setLoading(false)
      const totalPerPage = result.data.per_page;
      console.log(result)
      console.log(result.data.total / totalPerPage)
      setTotalRows(Math.ceil(result.data.total / totalPerPage));

    }catch(e){
        const response = e.response ? e.response : {};
        const data = response.data ? response.data : {};
        const msg = data.message ? data.message : "Ocorreu um erro ao listas os mailings";
        toast.error(msg)
        setLoading(false)
    }
  }

  const ConfirmDialog = (props) => {
    const { title, children, open, setOpen, onConfirm } = props;
    return (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            color="secondary"
          >
            Não
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            color="default"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleDeleteClick = () => {
      

      api.delete(`mailing/${deletedID}`).then(res => {
          search()
          toast.success("Mailing excluído");

      }).catch(error => {
          toast.error("Erro ao excluir");

          console.log(error)
      })
    
  };

  return (
    <Container fixed>

        <div>
            <h1>Mailing</h1>
        </div>
        <div
          style={{
            borderRadius: "5px",
            borderLeftWidth: "1px",
            borderRightWidth: "1px",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            backgroundColor:"#fff",
            padding:"10px",
            marginBottom: "10px"
          }}
        >   
              <Grid container style={{marginTop:"15px"}} >
                <Grid item xs={4} style={{marginRight:"15px"}}>
                    <TextField  
                    style={{marginLeft: "5px"}}
                    id="name"
                    label="Nome do Mailing"
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    value={name} 
                    type={"text"}
                    onChange={
                        evt => setName(evt.target.value)
                    } fullWidth /> 
                </Grid>
                <Grid item xs={6}>
                    <TextField  
                    style={{marginLeft: "5px"}}
                    id="corporate_name"
                    label="Razão Social"
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    value={corporateName} 
                    type={"text"}
                    onChange={
                        evt => handleCorporateName(evt.target.value)
                    } fullWidth /> 
                </Grid>
              </Grid>
              <Grid container style={{marginTop:"15px"}} >
                  <Grid item xs={5}>
                  <Button 
                      variant="contained" 
                      style={{color:"#4B8B33"}} 
                      onClick={() => { search() }}
                      href="#contained-buttons"
                      >
                      PESQUISAR
                    </Button>
                  </Grid>
              </Grid>
        </div>
        {
          permissisao.write ?  
          <div style={{paddingBottom:"10px"}}>
          <Link to="/mailing/new" style={{ textDecoration: 'inherit'}}>
              <Button variant="contained" style={{color:"#4B8B33"}} href="#contained-buttons">
                  NOVO
              </Button>
          </Link>
     
          </div> : ""
        }
       
      
        
        <TableContainer component={Paper}>
        {loading && <div style={{width: "100%"}}>
            <LinearProgress/>
          </div>}
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                <TableRow>
                    <StyledTableCell align="center">Nome</StyledTableCell>
                    <StyledTableCell align="center">Criado em</StyledTableCell>
                    <StyledTableCell align="center">Ações</StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.id}>
                            <StyledTableCell align="center" component="th" scope="row">
                                {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="center">{moment(row.created_at, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm")}</StyledTableCell>
                            <StyledTableCell align="center">
                              {permissisao.write ?    
                              <Link to={"/mailing/"+row.id+"/edit"} style={{ textDecoration: 'inherit'}}>
                                  <IconButton>
                                    <EditIcon/>
                                  </IconButton>
                              </Link>
                              : ""
                            }
                          {
                            permissisao.write ? 
                            <IconButton
                            color="inherit"
                            size="small"
                            aria-label="delete"
                            onClick={() => {
                                setConfirmOpen(true)
                                setDeletedID(row.id)
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton> : ""
                        }
                          
                            </StyledTableCell>
                            
                        </StyledTableRow>
                    ))}
                </TableBody>
                <TableFooter>
                <div style={{textAlign:"center",paddingTop:"10px",paddingBottom:"10px"}}>
                    <Pagination style={{textAlign:"center"}} count={totalRows} page={page} onChange={handleChangePage} />
                </div>
                </TableFooter>
            </Table>
        </TableContainer>
        <ConfirmDialog
                title="Excluir?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleDeleteClick}
            >
                Tem certeza de que deseja excluir?
        </ConfirmDialog>
        
    </Container>
  );
}
