import React, {useState} from 'react';

import {BrowserRouter as Router, Route, Switch as SwitchRoute} from 'react-router-dom';
import FormCustomer from './../containers/customers/FormCustomer'
import SiderBar from './SiderBar'
import paths from './paths.js';
import ListingCustomer from '../containers/customers/ListingCustomer'





const Routes = () => {

    // let clientes = JSON.parse(localStorage.getItem("clientes"));
    // let leads = JSON.parse(localStorage.getItem("leads"));
    // let usuarios = JSON.parse(localStorage.getItem("usuarios"));
    // let mailing = JSON.parse(localStorage.getItem("mailing"));
    
    
    return (
        <Router>
            <SiderBar paths={paths}> 
                <SwitchRoute>
                    {
                         paths.map((route, index) => {
                                let permissisao = localStorage.getItem(route.local)
                               
                                if((!route.local) || permissisao && JSON.parse(permissisao)[route.permissao]) {
                                    return (
                                        <Route path={route.to} exact component={route.container}  />
                                    )
                                }
                              
                         }
                        )
                    }
                </SwitchRoute>
            </SiderBar>
        </Router>
    );
}

export default Routes;