import React, { useState,useMemo, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import {TextField, Button, Modal, Box} from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import InputMask from 'react-input-mask';
import { DataGrid,GridToolbarContainer,useGridApiRef,ptBR  } from '@mui/x-data-grid';

import {useStyles} from "./styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/Add';
import {randomId} from '@mui/x-data-grid-generator';
import {Redirect, useParams} from "react-router-dom";
import moment from "moment"
import RelatorioLeadModal from "../../relatorioLead/index";
import RelatorioLeadTable from "../../relatorioLead/Table";
import api from '../../../services/api';
import { toast  } from 'react-toastify';
import { useHistory } from "react-router-dom";
import CurrencyInput from "../../../components/CurrencyInput";
import { format, parseISO } from 'date-fns';
import paisesJson from './paises.json'

function RowMenuCell(props) {
    const { api, id } = props;
    const classes = useStyles();
  
    const handleDeleteClick = (event) => {
      event.stopPropagation();
      console.log(event)
      api.updateRows([{ id, _action: 'delete' }]);
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          size="small"
          aria-label="delete"
          onClick={handleDeleteClick}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
    );
}



const columnsResponsible = [
    { field: 'name', headerName: 'Name', width: 400, editable: true},
    { field: 'email', headerName: 'E-mail', width: 200, editable: true },
    { field: 'telephone', headerName: 'Telefone', width: 150, editable: true },
    { field: 'linkedin', headerName: 'Linkedin', width: 150, editable: true },
    { field: 'cargo', headerName: 'Cargo', width: 150, editable: true },
    {
        field: 'actions',
        headerName: 'Ações',
        renderCell: RowMenuCell,
        sortable: false,
        width: 100,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true,
      },
];


const columnsAgendamentos = [
    { field: 'id', headerName: 'Número Reunião', width: 195 },
    { field: 'data_reuniao', headerName: 'Data da Reunião', width: 200 },
    { field: 'hora_reuniao', headerName: 'Hora da Reunião', width: 215 },
    { field: 'data_confirmacao', headerName: 'Data de Confirmação', width: 215 },
    { field: 'data_agendamento_assessor', headerName: 'Data de Agendamento', width: 230 },
    { field: 'assessor_name', headerName: 'Nome do Assessor', width: 215 },
    { field: 'cliente_name', headerName: 'Nome do Cliente', width: 215 },
    { field: 'tipo_agenda', headerName: 'Tipo de Agenda', width: 200 },
    // { field: 'resumo_contato', headerName: 'Resumo do Contato', width: 400 },
  ];


function useApiRef() {
    const apiRef = useGridApiRef();
    const _columns = useMemo(
      () => columnsResponsible.concat({
          field: "__HIDDEN__",
          width: 0,
          renderCell: (params) => {
            apiRef.current = params.api;
            return "";
          }
        }),
      [columnsResponsible]
    );
  
    return { apiRef, columnsResponsible: _columns };
}



// Função para transformar os dados e formatar as datas
const transformData = (data) => {
    return data.map((item) => ({
      id: item.id,
      data_reuniao: item.data_reuniao ? format(parseISO(item.data_reuniao), 'dd/MM/yyyy') : '',
      data_confirmacao: item.data_confirmacao ? format(parseISO(item.data_confirmacao), 'dd/MM/yyyy') : '',
      data_agendamento_assessor: item.data_agendamento_assessor ? format(parseISO(item.data_agendamento_assessor), 'dd/MM/yyyy') : '',
      assessor_name: item.assessor ? item.assessor.name : "",
      cliente_name: item.cliente ? item.cliente.name : "",
      tipo_agenda: item.tipo_agenda == "0" ? "Oportunidade" : "Reunião",
      hora_reuniao: item.hora_reuniao,
    //   resumo_contato: item.resumo_contato,
    }));
  };



const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const FormCustomer = (props) => {

    function EditToolbar(props) {
        const { apiRef, labelButton  } = props;
        const handleClick = () => {
          const id_row = "new_"+randomId();
        //   apiRef.current.updateRows([{ id, isNew: true }]);
      
        let rowsArr = [];   
        
        try {
            if(apiRef.current.getRowModels){
                let rows = apiRef.current.getRowModels();
                rows.forEach((row) =>{
                    rowsArr.push(row)
                })
            } 
        } catch (error) {
            
        }
 
    
    
        rowsArr.push({ id:id_row })
    
        setResponsibles(rowsArr)
    };
      
        return (
          <GridToolbarContainer>
            <Button id="button_1" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
              {labelButton}
            </Button>
          </GridToolbarContainer>
        );
    }
    
    const classes = useStyles();
    let { id, id_mailing } = useParams();
     
    const [active,setActive] = useState(false)
    const [name,setName] = useState("")

    const [corporateName,setCorporateName] = useState("")

    const [cnpj,setCnpj] = useState("")
    const [cep,setCep] = useState("")
    const [logradouro,setLogradouro] = useState("")
    const [neighborhood,setNeighborhood] = useState("")
    const [number,setNumber] = useState("")
    const [complement,setComplement] = useState("")
    const [city,setCity] = useState("")
    const [state,setState] = useState({sigla: null,nome: ""})

    const [pais,setPais] = useState({value: null})
    const [paises, setPaises] = useState(paisesJson);

    const [telephone,setTelephone] = useState("")
    const [responsibles,setResponsibles] = useState([])
    const [numberEmployees,setNumberEmployees] = useState([])
    const [site,setSite] = useState("")
    const [countResponsibles, setCountResponsibles] = React.useState(0);

    const [summary_contact,setSummaryContact] = useState("")

    const [statusSelected, setStatusSelected] = useState([]);
    const [followup, setFollowup] = useState(moment().toDate());
    const [revenues, setRevenues] = useState("0.00");

    const [users,setUsers] = useState([])

    const [cnae, setCnae] = useState("")
    const [assessores, setAssessores] = useState([]);
    const [assessorSelect, setAssessorSelect] = useState(true);
    const history = useHistory();
    const { apiRef, columnsResponsible } = useApiRef();
    
    const [agendas, setAgendas] = useState([])
    const [mask, setMask] = useState('*********************');


    const status = [
        {
            "id": 1,
            "name":"Em andamento"
        },
        {
            "id": 2,
            "name":"Retorno futuro"
        },
        {
            "id": 3,
            "name":"Aguardando retorno"
        },
        {
            "id": 4,
            "name":"Sem interesse"
        },
        {
            "id": 5,
            "name":"Sem perfil"
        },
        {
            "id": 6,
            "name":"Black list"
        },
        {
            "id": 7,
            "name":"Reunião agendada"
        },
    ]

    const queryParams = new URLSearchParams(window.location.search);
    const [mailing_leads_id, setMailingLeadsId] = useState();


       
    //resumos leads
    const [resumosLeads, setResumosLeads] = useState([]);
    const [mailings, setMailings] = useState([]);
    const [mailingsSelected, setMailingsSelected] = useState([]);
    const [cnaesSelected, setCnaesSelected] = useState([]);
    const [cnaes, setCnaes] = useState([])

    
    useEffect(() => {
        (async () => {
          const result = await api.get("cnae/listing");
          let data = result.data;
          setCnaes(data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
          const result = await api.get(`/lead/${id}/agendas`);
          let resultado = result.data;
          console.log("agenda",transformData(resultado.data))
          setAgendas(transformData(resultado.data));
        })();
    }, [id]);
    

    const handleMailings = (value) => {
        setMailingsSelected(value)
    };

    useEffect(() => {
        if(queryParams.get('mailing_leads_id') && queryParams.get('mailing_leads_id') !== "null" ){
            setMailingLeadsId(queryParams.get('mailing_leads_id') !== "null" ? queryParams.get('mailing_leads_id') : null)
        }
    }, []);
 
    useEffect(() => {
        (async () => {
            const result = await api.get(`mailings`,{
              params: {}
            });
            let data = result.data.data;
            console.log("mailings",data)
            setMailings(data)
        })()
    },[])


    //const [novosResumosLead, setNovosResumosLead] = React.useState([]);

    const salvarResumo = (resumo, data = null, followUp = null, responsible = null, terceirizacao = null, databid = null) => {
        setResumosLeads([{
            id: "new_" + Date.now(),
            resumo: resumo,
            data: data,
            assessor: "",
            follow_up: followUp,
            responsible_id: responsible.id,
            responsible: responsible,
            terceirizacao: terceirizacao.id,
            data_bid: databid,
            mailing_leads_id: mailing_leads_id,
        },...resumosLeads]);
    }

    const deleteResumo = (id) => {
        setResumosLeads(resumosLeads.filter(resumo => {
            if(resumo.id != id) {
                return resumo;
            }
        }));
    }

    useEffect(() => {
        (async () => {
            try {
                const result = await api.get("assessores");
                let data = result.data?.data;
                setAssessores(data);
            } catch (e) {
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.message ? data.message : "Ocorreu um erro ao capturar os dados do mailing";
                toast.error(msg)
            }
        })();
    }, []);
    
    useEffect(() => {
        if(!id) {
            return;
        }
        (async () => {
            try {
                const result = await api.get(`lead/${id}?mailing_leads_id=${queryParams.get('mailing_leads_id') !== "null" ? queryParams.get('mailing_leads_id') : null}`);
                let data = result.data;
                let statusSel = status.filter(statu => {
                    if(statu.id == data.status) {
                        return statu;
                    }
                })[0];
                let ufsSel = UFS.filter(uf => {
                    if(uf.sigla == data.state) {
                        return uf;
                    }
                })[0];
                if(data.responsible) {
                    setResponsibles(data.responsible);
                }
                if(data.relatorios) {
                    setResumosLeads(data.relatorios);
                }
                if(data.assessor_id) {
                    const result = await api.get("assessor/"+data.assessor_id);
                    setAssessorSelect(result.data);
                }
                setActive(data.ativo);
                setCorporateName(data.corporate_name);

                setCep(data.zip_code);
                setLogradouro(data.logradouro ? data.logradouro : "");
                setNumber(data.number ? data.number : "");
                setComplement(data.complement ? data.complement : "");
                setCity(data.city);

                

                let paisSelecionado = await paisesJson.filter(valor => {
                    if(valor.value == data.pais) {
                        console.log("pais",valor.value)
                        return valor;
                    }
                })[0];

                // let paisJson = {"nome":`${data.pais}`,"value":`${data.pais}`}
                setPais(paisSelecionado);

                if(ufsSel) {
                    setState(ufsSel);
                }else if(data.state){
                    setState({"nome":data.state,"sigla":data.state});
                }
               
                setCnae(data.cnae);

                setCnpj(data.cnpj);
                
                if(data.cnpj.length == 14){
                    setMask('99.999.999/9999-99');
                }
                
                setRevenues(data.revenues);
                setNumberEmployees(data.number_employees);
                setSite(data.site);
                setStatusSelected(statusSel);
                setSummaryContact(data.contact_summary);
                setTelephone(data.telephone);
                setCep(data.zip_code);
                setNeighborhood(data.neighborhood);
                setMailingsSelected(data.mailings)
                setCnaesSelected(data.cnae_detalhe)

                // fetch(`https://viacep.com.br/ws/${cep}/json/`)
                // .then((res) => res.json())
                // .then((data) => {
    
                //     if(data.erro && data.erro == true){
                //         return false;
                //     }
        
                //     setLogradouro(data.logradouro)
                //     setNeighborhood(data.bairro)
                //     setCity(data.localidade)
                // });

            }catch(e) {
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.message ? data.message : "Ocorreu um erro ao capturar os dados do mailing";
                toast.error(msg)
            }
        })();
    }, []);

    const UFS =  [
        {"nome": "Acre", "sigla": "AC"},
        {"nome": "Alagoas", "sigla": "AL"},
        {"nome": "Amapá", "sigla": "AP"},
        {"nome": "Amazonas", "sigla": "AM"},
        {"nome": "Bahia", "sigla": "BA"},
        {"nome": "Ceará", "sigla": "CE"},
        {"nome": "Distrito Federal", "sigla": "DF"},
        {"nome": "Espírito Santo", "sigla": "ES"},
        {"nome": "Goiás", "sigla": "GO"},
        {"nome": "Maranhão", "sigla": "MA"},
        {"nome": "Mato Grosso", "sigla": "MT"},
        {"nome": "Mato Grosso do Sul", "sigla": "MS"},
        {"nome": "Minas Gerais", "sigla": "MG"},
        {"nome": "Pará", "sigla": "PA"},
        {"nome": "Paraíba", "sigla": "PB"},
        {"nome": "Paraná", "sigla": "PR"},
        {"nome": "Pernambuco", "sigla": "PE"},
        {"nome": "Piauí", "sigla": "PI"},
        {"nome": "Rio de Janeiro", "sigla": "RJ"},
        {"nome": "Rio Grande do Norte", "sigla": "RN"},
        {"nome": "Rio Grande do Sul", "sigla": "RS"},
        {"nome": "Rondônia", "sigla": "RO"},
        {"nome": "Roraima", "sigla": "RR"},
        {"nome": "Santa Catarina", "sigla": "SC"},
        {"nome": "São Paulo", "sigla": "SP"},
        {"nome": "Sergipe", "sigla": "SE"},
        {"nome": "Tocantins", "sigla": "TO"}
    ];

    const handleActive = (event) => {
        setActive(event.target.checked)
    };


    const handleCorporateName = (event) => {
        setCorporateName(event.target.value)
    };


    const handleCNAE = (event) => {
        setCnae(event.target.value)
    };

    function realParaNumber(texto){
        var compativelComParseFloat = texto.replace("R$ ","");
        compativelComParseFloat = compativelComParseFloat.replaceAll(".","");
        // compativelComParseFloat = compativelComParseFloat.replace(".","");
        // compativelComParseFloat = compativelComParseFloat.replace(".","");
        compativelComParseFloat = compativelComParseFloat.replace(",",".");
        // var valor = parseFloat(compativelComParseFloat);
        return compativelComParseFloat;
    }

    const handleRevenues = (event, value, maskedValue) => {
        var currency = event.target.value;

        var number = realParaNumber(currency)
    
        setRevenues(number)
    };

    const handleNumberEmployees = (event) => {
        setNumberEmployees(event.target.value)
    };

    const handleSite = (event) => {
        setSite(event.target.value)
    };

    const handleCnpj = (event) => {
        let value = event.target.value
        if (value.length !== 14) {
            setMask('************************');
          } else {
            setMask('99.999.999/9999-99');
          }

        setCnpj(value.replace(/[^0-9]/g, ''))
    };

    const handleCep = (event) => {
        let cep = event.target.value.replace(/[^0-9]/g, '')

        setCep(cep)
        
   
        if(cep.length === 8){
            fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then((res) => res.json())
            .then((data) => {

                if(data.erro && data.erro == true){
                    return false;
                }
    
                setLogradouro(data.logradouro ? data.logradouro : "")
                setNeighborhood(data.bairro ? data.bairro : "")
                setCity(data.localidade ? data.localidade : "")

                setNumber("")
                
                let state = {sigla: null,nome: ""}
                UFS.map((uf)=>{
                    if(uf.sigla == data.uf){
                        state = uf
                       
                    }
                })

                setState(state)
              
    
            }).catch((error) =>{
                alert("Erro")
            });
        }

    };

    const handleLogradouro = (event) => {
        setLogradouro(event.target.value)
    };

    const handleNumber = (event) => {
        setNumber(event.target.value.replace(/[^0-9]/g, ''))
    };

    const handleComplement = (event) => {
        setComplement(event.target.value)
    };

    const handlePais = (value) => {
        setState({sigla:null,nome:""})
        setPais(value)
    }

    const handleNeighborhood = (event) => {
        setNeighborhood(event.target.value)
    }

    const handleSummaryContact = (event) => {
        setSummaryContact(event.target.value)
    };
    

    const handleCity = (event) => {
        setCity(event.target.value)
    };

    const handleState = (value) => {
        if(value) {
            setState(value)
        }else{
            setState({sigla: null})
        }
       
    };

    const handleTelephone = (event) => {
        let tel = event.target.value.replace(/[^0-9]/g, '')
        setTelephone(tel)
    }

    const handleFollowup = (value) => {
        setFollowup(value)
    }

  

    const handleStatusSelected = (value) => {
        setStatusSelected(value);
    };




    const handleSave = async () => {
        let contacts = []
        try {
            if(apiRef.current) {
                contacts = apiRef.current.getRowModels();
            }
        } catch (error) {
            
        }
    
        let contactsArr = [];   
        contacts.forEach((contact) =>{
            contactsArr.push(contact)
        })


        let mailings = [];
        mailingsSelected.forEach((mailing) =>{
            mailings.push(mailing.id)
        })
                

        if(!cnaesSelected?.cnae) {
            toast.error("CNAE é obrigátorio")
        }

        if(!corporateName) {
            toast.error("Razão Social é obrigátorio")
        }

        if(!cnpj) {
            toast.error("CNPJ é obrigátorio")
        }

        let data = {
            ativo: active,
            corporate_name: corporateName,
            zip_code: cep,
            logradouro,
            number,
            complement,
            city,
            state: state?.sigla,
            pais: pais?.value,
            responsible: contactsArr,
            cnae: cnaesSelected?.cnae,
            cnpj,
            neighborhood: neighborhood,
            revenues: revenues,
            number_employees: numberEmployees,
            site: site,
            telephone: telephone,
            date_followup: followup,
            mailing_id: id_mailing,
            contact_summary: summary_contact,
            relatorios: resumosLeads,
            mailings: mailings
        };

        try {
            let data_lead = null;
            if(id) {
                data_lead = await api.patch(`lead/${id}/save`, data);
                toast.success("Lead atualizado com sucesso");
            }
            else {
                data_lead = await api.post("lead/save", data);
                toast.success("Lead criado com sucesso");
            }
      
        } catch(e) {
            console.log(e);
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.message ? data.message : "Ocorreu um erro ao salvar o Lead";
            toast.error(msg)
        }
    };

    const handleNovaReuniao =  () => {
        history.push("/agendamento-lead/novo?id_lead="+id);
    }
    
    const currencyConfig = {
        locale: "pt-BR",
        formats: {
          number: {
            BRL: {
              style: "currency",
              currency: "BRL",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          },
        },
      };

    return (
            <Container fixed>
                <h1> {!id ? "Novo Lead" : "Editar Lead" } </h1>
                <form  noValidate autoComplete="off">
                    <Grid xs={12}>
                        <FormControlLabel
                            control={<GreenCheckbox checked={active} onChange={handleActive} name="active" />}
                            label="Ativo"
                        />
                    </Grid>    
                    <Grid style={{display:"flex" }}>
                        

                        <Grid item xs={6}>
                            <TextField required id="standard-required"  label="Razão Social" value={corporateName} onChange={handleCorporateName} fullWidth />
                        </Grid>
                        <Grid item xs={2}>
                            <InputMask mask={mask} value={cnpj} onChange={handleCnpj}>
                                {(inputProps) => <TextField {...inputProps} required  label="CNPJ" />}
                            </InputMask>
                        </Grid>
                        <Grid item xs={2}>
                            {/* <TextField required id="standard-required" type={"number"}  label="Faturamento" value={revenues} onChange={handleRevenues} fullWidth /> */}
                            {/* <IntlCurrencyInput label="Faturamento" currency="BRL" config={currencyConfig}
            o               nChange={handleRevenues} /> */}
                            {/* <InputMask mask="+4" value={cnpj} onChange={handleRevenues}>
                                {(inputProps) => <TextField {...inputProps} required  label="Faturamento" />}
                            </InputMask> */}
                            <label>Faturamento</label>
                            <CurrencyInput placeholder="$0.00" type="text" value={revenues} onChange={handleRevenues} />

                            {/* <CurrencyInput placeholder="$0.00" type="text" >
                                {(inputProps) => <TextField {...inputProps} required  label="CNPJ" />}
                            </CurrencyInput> */}
                        </Grid>
                        <Grid item xs={2}>
                            <TextField required id="standard-required" type={"number"}  label="N de funcionários" value={numberEmployees} onChange={handleNumberEmployees} fullWidth />
                        </Grid>

                    </Grid>
                    <Grid style={{display:"flex" }}>
                        <Grid item xs={2}>
                            {/* <TextField required id="standard-required"  label="CNAE" value={cnae} onChange={handleCNAE} fullWidth />
                             */}
                            <Autocomplete
                                disablePortal
                                id="cnae"
                                options={cnaes}
                                onChange={(event, newInputValue) => {
                                    setCnaesSelected(newInputValue);
                                }}
                                value={cnaesSelected}
                                getOptionLabel={(option) => option.nome || ""}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params}  label="CNAE" required />}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField required id="standard-required"  label="Site" value={site} onChange={handleSite} fullWidth />
                        </Grid>
                        <Grid item xs={2}>
                            <InputMask mask="(99) 99999-9999" value={telephone} onChange={handleTelephone}>
                                {(inputProps) => <TextField {...inputProps}   label="Telefone" />}
                            </InputMask>
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                disablePortal
                                id="mailings"
                                multiple
                                options={mailings}
                                onChange={(event, newInputValue) => {
                                    handleMailings(newInputValue);
                                }}
                                value={mailingsSelected}
                                getOptionLabel={(option) => option.name || ""}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params}  label="Mailing" />}
                            />
                        </Grid>
                    </Grid>
                    <Grid style={{display:"flex" }}>
                        <Grid item xs={2}>
                            <InputMask mask={"99.999.999"} value={cep} onChange={handleCep}>
                                {(inputProps) => <TextField required  {...inputProps}   label="CEP" />}
                            </InputMask>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required id="standard-required" label="Logradouro" onChange={handleLogradouro} value={logradouro} fullWidth />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField required id="standard-required"  label="Número" onChange={handleNumber} value={number} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField  id="standard-required" label="Complemento" onChange={handleComplement} value={complement} fullWidth />
                        </Grid>
                    </Grid>  

                    <Grid style={{display:"flex" }}>
                        <Grid item xs={3}>
                            <TextField required id="standard-required" label="Bairro" onChange={handleNeighborhood} value={neighborhood} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField required id="standard-required" label="Cidade" onChange={handleCity} value={city} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            {/* <TextField  id="standard-required" label="País" onChange={handlePais} value={pais} fullWidth /> */}
                            <Autocomplete
                            disablePortal
                            id="pais"
                            options={paises}
                            onChange={(event, newInputValue) => {
                                handlePais(newInputValue);
                            }}
                            value={pais}
                            getOptionLabel={(option) => option.nome || ""}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="País" required />}
                        />
                        </Grid>
                        <Grid item xs={2}>

                            {pais?.value == "Brasil" ? (<Autocomplete
                                disablePortal
                                id="state"
                                options={UFS}
                                onChange={(event, newInputValue) => {
                                    console.log(newInputValue)
                                handleState(newInputValue);
                                }}
                                value={state}
                                getOptionLabel={(option) => option.nome || ""}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params}  label="Estado" required />}
                            />) : (<TextField required id="standard-required" label="Estado" onChange={(event) => handleState({"nome":event.target.value, "sigla": event.target.value})} value={state.nome} fullWidth  />)
                            }
                         
                        </Grid>
                      
                    </Grid>

                    {/* <Grid item xs={12} style={{display:"flex" , paddingTop: 10}}>
                            <Grid item xs={12}>
                                <label style={{color:"rgba(0, 0, 0, 0.54)",fontSize:"16px"}}>Decisores</label>
                            </Grid>

                            <div style={{height: 450}}>
                                
                            </div>
                   
                           
                    </Grid> */}
                            <DataGrid
                                autoPageSize 
                                pagination
                                style={{height:500,paddingTop:20}}
                                rows={responsibles} 
                                pageSize={5}
                                columns={columnsResponsible} 
                                editMode="row"
                                localeText={ptBR.props.MuiDataGrid.localeText} 
                                components={{
                                    Toolbar: EditToolbar,
                                }}
                                componentsProps={{
                                    toolbar: { apiRef, labelButton:"ADICIONAR DECISOR" },
                                }}
                            /> 
                    <Grid style={{display:"flex", paddingTop: 10 }} >
                        <Grid item xs={12} style={{ paddingTop: 20 }}>
                            <RelatorioLeadModal salvarResumo={salvarResumo} responsibles={responsibles} />

                            <RelatorioLeadTable deleteResumo={deleteResumo} resumos={resumosLeads}/>
                            <hr style={{height: "5px", backgroundColor: "#6E6E6E", marginTop: "20px"}} />
                            <br />
                            <h2>Histórico de Agendamentos</h2>
                            <DataGrid
                                autoPageSize 
                                pagination
                                style={{height:500,paddingTop:20}}
                                rows={agendas} 
                                pageSize={5}
                                columns={columnsAgendamentos} 
                                editMode="row"
                                localeText={ptBR.props.MuiDataGrid.localeText} 
                            /> 
                        </Grid>
                        
                    </Grid>
                    <Grid style={{display:"flex", paddingTop: 20 }} >
                        <Grid item xs={1}>
                            <Button id="salvar_form" variant="contained" color="primary"  onClick={handleSave}>
                                SALVAR
                            </Button>
                        </Grid>
                        {id && <Grid item xs={2}>
                            <Button id="salvar_form" variant="contained" color="primary"  onClick={handleNovaReuniao}>
                                NOVA REUNIÃO
                            </Button>
                        </Grid>
                        }
                    
                    </Grid>
      

                </form>
            </Container>
    )
}


export  default FormCustomer;