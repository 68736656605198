export default [
    {
        "local": "usuarios",
        "titulo": "Usúarios"
    },
    {
        "local": "clientes",
        "titulo": "Clientes"
    },
    {
        "local": "mailing",
        "titulo": "Mailing"
    },
    {
        "local": "leads",
        "titulo": "Leads"
    },
    {
        "local": "kpi",
        "titulo": "KPIs"
    },
]