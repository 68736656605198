import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from "../../../services/api";

import { DataGrid,
    ptBR
} from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton  } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import UploadFileIcon from '@material-ui/icons/CloudUpload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DownloadIcon from '@material-ui/icons/GetApp';
import dayjs from 'dayjs';

// Estilizar o input de arquivo para ficar invisível
const Input = styled('input')({
  display: 'none',
});


const ImportacaoContatos = (props) => {
    const [name, setName] = useState("");
    const [rows, setRows] = useState([]);
    const [openUpload, setOpenUpload] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [openLog, setOpenLog] = useState(false);
    const [logDetails, setLogDetails] = useState([]);

    useEffect(() => {
        setName(localStorage.getItem("name"));

        // Simulando a obtenção de dados para as linhas da tabela
        // Você deve substituir isso com uma chamada axios para obter dados reais
        // setRows([
        //     { id: 1, dataHora: '2023-05-01 12:00', agendadoEm: '2023-04-30 10:00', importadoEm: '2023-05-01 12:30', status: 'Concluído', logs: '1' },
        //     { id: 2, dataHora: '2023-05-02 14:00', agendadoEm: '2023-04-30 11:00', importadoEm: '2023-05-02 14:30', status: 'Concluído', logs: '2' },
        //     // Adicione mais linhas conforme necessário
        // ]);
        importacoes()
    }, []);

    const importacoes = async () => {
        const response = await api.get(`/uploads?entidade=contatos-leads`);
        const data = response["data"]
        setRows(data["rows"])
    }
    
    const formatDate = (date) => {
        if(!date) {
            return ""
        }
        return dayjs(date).format('DD/MM/YYYY HH:mm:ss');
    };

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { 
            field: 'created_at', 
            headerName: 'Agendado Em', 
            flex: 1, 
            valueGetter: (params) => formatDate(params.row.created_at),

        },
        { 
            field: 'datahora_importacoes', 
            headerName: 'Importado Em', 
            flex: 1 ,
            valueGetter: (params) => formatDate(params.row.datahora_importacoes),

        },
        { field: 'status', headerName: 'Status', flex: 0.5 },
        {
            field: 'logs',
            headerName: 'Logs',
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <IconButton
                        color="primary"
                        onClick={() => handleOpenLog(params.row.id)}
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton
                        color="primary"
                        onClick={() => handleDownload(params.row.id)}
                    >
                        <DownloadIcon />
                    </IconButton>
                </Box>
            ),
        }
    ];

    const logColumns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        { 
            field: 'created_at', 
            headerName: 'Criado Em', 
            flex: 1, 
            valueGetter: (params) => formatDate(params.row.created_at),

        },        
        { field: 'mensagem', headerName: 'Mensagem', flex: 2 }
    ];

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setOpenUpload(true);
        }
    };

    const handleUpload = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('entidade', "contatos-leads");

            try {
                const response = await api.post('/upload-xlsx', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log(response.data); // Aqui você pode lidar com a resposta do servidor
                setOpenUpload(false); // Fechar a modal após o upload
                importacoes()
            } catch (error) {
                console.error('Error uploading the file:', error);
            }
        }
    };

    const handleOpenLog = async (logId) => {
        try {
            
            const response = await api.get(`importacao/logs/${logId}`);
            setLogDetails(response.data); // Supõe que response.data é um array de logs
            setOpenLog(true);
        } catch (error) {
            console.error('Error fetching the log details:', error);
        }
    };

    const handleCloseUpload = () => {
        setOpenUpload(false);
    };

    const handleCloseLog = () => {
        setOpenLog(false);
    };

    const handleDownload  = (id) => {
        api({
            url: `planilha-importada/${id}`, // Substitua pela URL correta para download do modelo
            method: 'GET',
            responseType: 'blob', // Importante
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `leads-importados.xlsx`); // Nome do arquivo de download
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((error) => {
            console.error('Error downloading the template:', error);
        });
    }

    const handleDownloadTemplate = () => {
        api({
            url: `planilha-modelo?entidade=contatos-leads`, // Substitua pela URL correta para download do modelo
            method: 'GET',
            responseType: 'blob', // Importante
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `modelo-importacao.xlsx`); // Nome do arquivo de download
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((error) => {
            console.error('Error downloading the template:', error);
        });
    };

    return (
        <>
            <div style={{ textAlign: "center" }}>
                <h2>Importação contatos do Lead</h2>
            </div>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <label htmlFor="upload-file">
                    <Input
                        accept=".xlsx"
                        id="upload-file"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <Button variant="contained" component="span" startIcon={<UploadFileIcon />}>
                        Upload Excel Planilha de Contatos
                    </Button>
                </label>
                <Button
                    variant="contained"
                    style={{backgroundColor:"#4B8B33", color:"#fff"}}
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadTemplate}
                >
                    Planilha Modelo
                </Button>
            </Box>
            <Box height={400} width="100%">
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    autoHeight
                    localeText={ptBR.props.MuiDataGrid.localeText}
                />
            </Box>
            {/* Modal de Confirmação de Upload */}
            <Dialog
                open={openUpload}
                onClose={handleCloseUpload}
            >
                <DialogTitle>Confirmação de Upload</DialogTitle>
                <DialogContent>
                    Tem certeza que deseja fazer o upload deste arquivo?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUpload} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleUpload} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Modal de Logs */}
            <Dialog
                open={openLog}
                onClose={handleCloseLog}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Detalhes do Log</DialogTitle>
                <DialogContent>
                    <Box height={400} width="100%">
                        <DataGrid
                            rows={logDetails}
                            columns={logColumns}
                            pageSize={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            autoHeight
                            localeText={ptBR.props.MuiDataGrid.localeText}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseLog} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ImportacaoContatos;
