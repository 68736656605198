import { useState, useEffect } from "react";
import * as axios from 'axios';

import InputMask from 'react-input-mask';
import { useForm, Controller } from "react-hook-form";
import api from "../../services/api";
import { useLocation, useParams } from "react-router-dom";
import RelatorioLeadModal from "../relatorioLead/index";
import RelatorioLeadTable from "../relatorioLead/Table";
import {
    Grid,
    TextField,
    FormLabel,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    Select,
    MenuItem,
    InputLabel,
    Button,
    TextareaAutosize
} from "@material-ui/core";
import { toast  } from 'react-toastify';

import LeadInfo from "../../components/LeadInfo";
import DadosContato from "../../components/DadosContato";
import DadosDecisor from "../../components/DadosDecisor";
import Rating  from 'react-rating';
import { faStar, FasStar } from '@fortawesome/free-solid-svg-icons'


const FormAgendamentoLead = () => {
    const { control, register, handleSubmit, reset, formState: { errors }, watch, setValue, setFocus } = useForm({});
    const [leads, setLeads] = useState([]);
    const [assessores, setAssessores] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [contacts, setContacts] = useState([]);

    const location = useLocation();
    const {id} = useParams();

    const editar = false;
    const queryParams = new URLSearchParams(window.location.search);
    const [id_lead, setIdLead] = useState(queryParams.get('id_lead') !== "null" ? queryParams.get('id_lead') : "");

    const [user, setUser] = useState({});
    const [naoAprovado, setNaoAprovado] = useState(false);
    const [resumos, setResumos] = useState([]);
    const statusWatch = watch("status");
    const tipo_agenda = watch("tipo_agenda");
    const status_auditoria = watch("status_auditoria");

    
    
    // useEffect(() => {
    //     if(statusWatch === "nao_aprovada") {
    //         setNaoAprovado(true);
    //     } else {
    //         setNaoAprovado(false);
    //     }

    //     if(statusWatch === "reagendada") {
    //         setValue("data_reuniao", "");
    //         setFocus("data_reuniao");
    //         toast.warning("Insira uma nova data de reunião");
    //     } 
    //     if(statusWatch === "aguardando_nova_data") {
    //         setValue("data_reuniao", "");
    //     }
    // }, [statusWatch]);

    const alterarStatus =  (value) => {
        let newStatus = value.target.value;

        if(newStatus === "nao_aprovada") {
            setNaoAprovado(true);
        } else {
            setNaoAprovado(false);
        }

        if(newStatus === "reagendada") {
            setValue("data_reuniao", "");
            setFocus("data_reuniao");
            toast.warning("Insira uma nova data de reunião");
        } 

        if(newStatus === "aguardando_nova_data") {
            setValue("data_reuniao", "");
        }

        setValue("status", newStatus)

    }

    const listingContacts =  async (id) => {
        try {
            const result = await api.get(`customers/${id}/contacts`);
            let data = result.data;
            setContacts(data);
        } catch (e) {
            const response = e.response ? e.response : {};
            const data = response.data ? response.data : {};
            const msg = data.message ? data.message : "Ocorreu um erro ao capturar os dados de contatos";
            toast.error(msg)
        }

    }

    const [dataLead, setDataLead] = useState({});
    const [relatoriosLead, setRelatorisoLead] = useState([]);

    const [decisor, setDecisor] = useState(null);

    const contactIdWatch = watch("contact_id");
    const clienteIdWatch = watch("cliente");


    useEffect(() => {
        (async () => {
            try {
                if(clienteIdWatch) {
                    await listingContacts(clienteIdWatch)
                }
            } catch(e) {
                console.log(e);
                toast.error("Não foi possivel consultar o contatos");
            }
        })();
    }, [clienteIdWatch]);


    useEffect(() => {
        try {
            if(status_auditoria == "0") {
                setValue("status", "nao_aprovada");
            }
        } catch(e) {
        }
    }, [status_auditoria]);
    
    useEffect(() => {
        (async () => {
            try {
                if(contactIdWatch) {
                    const result = await api.get("lead-responsible/"+contactIdWatch);
                    setDecisor(result.data);
                    setIdLead(result.data.lead_id)    
                }
            } catch(e) {
                console.log(e);
                toast.error("Não foi possivel consultar o decisor");
            }
        })();
    }, [contactIdWatch]);

    useEffect(() => {
        if(id_lead && id_lead !== "null") {
            (async () => {
                try {
                    const result = await api.get("lead/"+id_lead);
                    let data = result.data;
                    setDataLead(data);
                } catch(e) {
                    toast.error("Não foi possivel consultar o lead");
                }
            })();
        }
    }, [id_lead]);
    useEffect(async () => {
        try {
            let data = [];
            data = await api.get("lead-responsibles?id_lead="+id_lead);
            setLeads(data.data.data);
            if(id) {
                data = await api.get("assessor/get/select");
                setAssessores(data.data);
                data = await api.get("customers/select/get");
                setClientes(data.data);
            }else{
                data = await api.get("assessor/get/select",{
                    params: { 
                        "assessor_id": localStorage.getItem("id_assessor")
                        }
                });
                setAssessores(data.data);
                data = await api.get("customers/select/get",{
                    params: { 
                        "assessor_id": localStorage.getItem("id_assessor")
                        }
                });
                setClientes(data.data);
            }
          

            //se editar estiver ok
            if(id) {
                data = await api.get("agendamento-lead/"+id);
                setUser(data.data);
                if(Array.isArray(data.data.informacoes_adicionais)) {
                    setResumos(data.data.informacoes_adicionais);
                }
            }

        } catch(exception) {
            toast.error(exception.message);
        }        
    }, []);
    useEffect(() => {
        reset(user);
    }, [user]);

    const onSubmit = async data => {
        try {
            data.relatorios = resumos;
            let result;
            if(id) {
                result = await api.patch("agendamento-lead/save/"+id, data);
            } else {
                result = await api.post("agendamento-lead/save", data);
                setResumos([]);
                reset();
                setDecisor(null)
            }
            toast.success(result.data);

        } catch(exception) {
            console.log(exception);
            toast.error(exception.message);
        }
    };
    const salvarResumo = (resumo, data = null, followUp = null, responsible = null, terceirizacao = null, databid = null) => {
        setResumos([{
            id: "new_" + Date.now(),
            resumo: resumo,
            data: data,
            assessor: "",
            follow_up: followUp,
            responsible_id: responsible.id,
            responsible: responsible,
            terceirizacao: terceirizacao.id,
            data_bid: databid,
        },...resumos]);
    }

    const deleteResumo = (id) => {
        setResumos(resumos.filter(resumo => {
            if(resumo.id != id) {
                return resumo;
            }
        }));
    }


    const downloadBrifing = (id) => {

        axios({
            method: 'get',
            url: 'https://api-crm.prospectbrasil.com.br/api/briefing/' + id,
            responseType: 'arraybuffer',
            data: {}
          }).then(function(response) {
            let blob = new Blob([response.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'briefing-' + id + '.pdf'
            link.click()
          })

    }
   

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1>{id ? "Reunião Agendada" : "Nova Reunião Agendada"}</h1>
                </Grid>
                <Grid container>
                    <Grid item xs={3}>
                    <Controller 
                            name="numero_reuniao"
                            defaultValue={"AUTO"}
                            control={control}
                            render={({field}) => (
                                <TextField fullWidth variant="standard" {...field} label="Número da reunião"  disabled={true} />

                            )}
                        />
                        
                    </Grid>
                    <Grid item xs={3} style={{marginLeft:10}}>
                        <FormControl fullWidth>
                            <InputLabel>Cliente *</InputLabel>
                            <Controller
                                name="cliente"
                                control={control}
                                defaultValue=""
                                rules={{required: true}}
                                render={({field}) => (
                                    <Select {...field} disabled={editar}>
                                        {clientes.map(cliente => {
                                            return <MenuItem key={cliente.id} value={cliente.id}>{cliente.name}</MenuItem>
                                        })}
                                    </Select>
                                )}
                            />
                            {errors.cliente && <span style={{color: "red"}}>O campo Cliente é obrigatorio</span>}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container style={{marginTop:10}}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel>Assessor *</InputLabel>
                            <Controller 
                                name="assessor"
                                control={control}
                                defaultValue=""
                                rules={{required: true}}
                                render={({field}) => (
                                    <Select {...field} disabled={editar}>
                                        {assessores.map(assessor => {
                                            return  <MenuItem key={assessor.id} value={assessor.id}>{assessor.name}</MenuItem>
                                        })}
                                    </Select>      
                                )}
                            />
                            {errors.assessor && <span style={{color: "red"}}>O campo Assessor é obrigatorio</span>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} style={{marginLeft:10}}>
                        <FormControl fullWidth>
                            <InputLabel>Executivo da reunião: *</InputLabel>
                            <Controller 
                                name="executivo_reuniao"
                                control={control}
                                defaultValue=""
                                rules={{required: true}}
                                render={({field}) => (
                                    <Select {...field} disabled={editar}>
                                        {contacts.map(contact => {
                                            return  <MenuItem key={contact.id} value={contact.id}>{contact.name}</MenuItem>
                                        })}
                                    </Select>      
                                )}
                            />
                            {errors.assessor && <span style={{color: "red"}}>O campo Executivo da reunião: é obrigatorio</span>}
                        </FormControl>
                    </Grid>
                </Grid>


                <Grid item xs={12}> 
                <br />
                <hr style={{borderStyle:"solid",borderWidth:0.5}} />
                <br />
                </Grid>
                <Grid container>
                    <Grid item xs={3}>
                        <FormControl>
                            <Controller
                                control={control}
                                name={"tipo_agenda"}
                                rules={{required: true}}
                                render={({field: {onChange, value}}) => (
                                    <RadioGroup
                                        row
                                        defaultValue={"0"}
                                        value={String(value)}
                                        onChange={onChange}
                                    >
                                        <FormControlLabel disabled={editar} value={"1"} control={<Radio />} label="Reunião" />
                                        <FormControlLabel disabled={editar} value={"0"} control={<Radio />} label="Oportunidade" />
                                    </RadioGroup>
                                )}
                            />
                            {errors.enviou_convite && <span style={{color: "red"}}>Selecione se é uma reunião ou oportunidade</span>}
                        </FormControl>
                    </Grid>
                    
                </Grid>
                <Grid container>
                    <Grid item xs={3}>
                        {tipo_agenda == "1" ?  <FormLabel>Data da reunião</FormLabel> : <FormLabel>Data</FormLabel> } 
                        <TextField fullWidth type={"date"} variant="standard" 
                            disabled={editar}
                            {...register("data_reuniao", {required: false})} />
                    </Grid>
                    {tipo_agenda == "1" &&<Grid item xs={3}>
                        <FormLabel>Hora da Reunião</FormLabel>
                        <TextField fullWidth type={"time"} variant="standard" 
                            disabled={editar}
                            {...register("hora_reuniao", {required: false})} />
                    </Grid>}
                </Grid>
                {tipo_agenda == "1" && <Grid container style={{marginTop:10}}>
                    <Grid item xs={3}>
                        <FormControl>
                            <FormLabel>Formato</FormLabel>
                            <Controller
                                control={control}
                                name={"formato"}
                                rules={{required: true}}
                                render={({field: {onChange, value}}) => (
                                    <RadioGroup
                                        row
                                        defaultValue={""}
                                        value={String(value)}
                                        onChange={onChange}
                                    >
                                        <FormControlLabel disabled={editar} value={"1"} control={<Radio />} label="Call" />
                                        <FormControlLabel disabled={editar} value={"2"} control={<Radio />} label="Videoconferência" />
                                        <FormControlLabel disabled={editar} value={"3"} control={<Radio />} label="Presencial" />
                                        
                                    </RadioGroup>
                                )}
                            />
                            {errors.enviou_convite && <span style={{color: "red"}}>Selecione o Formato</span>}
                        </FormControl>
                    </Grid>
                </Grid>}
          
                <Grid item xs={12}> 
                <br />
                <hr style={{borderStyle:"solid",borderWidth:0.5}} />
             
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        {id_lead && <h2>Lead:</h2>}
                        {id_lead && <LeadInfo dataLead={dataLead}/>}
                    </Grid>
                    {/* <Grid item xs={12}>
                        {id_lead && <h2>Dados do contato:</h2>}
                        {id_lead && relatoriosLead.map(relatorio => {
                            return <DadosContato data={relatorio}/>
                        })}
                    </Grid> */}
                    <Grid item xs={12}>
                        <h2>Dados do contato:</h2>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel>Decisor *</InputLabel>
                                <Controller 
                                    defaultValue=""
                                    control={control}
                                    name="contact_id"
                                    rules={{required: true}}
                                    render={({field}) => (
                                        <Select {...field} disabled={editar}>
                                            {leads.map(lead => {
                                                return  <MenuItem key={lead.id} value={lead.id}>{lead.name}</MenuItem>
                                            })}
                                        </Select>
                                    )}
                                />
                                {errors.contact_id && <span style={{color: "red"}}>O campo Decisor é obrigatorio</span>}
                            </FormControl>
                        </Grid>
                        {decisor && <DadosDecisor data={decisor}/>}
                        {decisor &&<Grid item xs={6}>
                            <Controller 
                                name="resumo_contato"
                                defaultValue=""
                                control={control}
                                render={({field}) => (
                                    <TextareaAutosize disabled={editar} aria-label="minimum height" minRows={3} maxRows={10} cols={100} label="Resumo do contato" {...field} />
                                    // <TextareaAutosize fullWidth disabled={editar} variant="standard" label="Resumo do contato" {...field} />
                                )}
                            />
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12}> 
                <br />
                <hr style={{borderStyle:"solid",borderWidth:0.5}} />
                </Grid>
                <Grid container> 
                    <h2>Auditoria</h2>
                </Grid>
                <Grid container> 
                    <Grid item xs={3}>
                        <FormControl>
                            <FormLabel>Status</FormLabel>
                            <Controller
                                control={control}
                                name={"status_auditoria"}
                                rules={{required: false}}
                                render={({field: {onChange, value}}) => (
                                    <RadioGroup
                                        row
                                        value={String(value)}
                                        onChange={onChange}
                                    >
                                       
                                        <FormControlLabel disabled={editar} value={"2"} control={<Radio />} label="Aguardando" />
                                        <FormControlLabel disabled={editar} value={"1"} control={<Radio />} label="Aprovada" />
                                        <FormControlLabel disabled={editar} value={"3"} control={<Radio />} label="Não Aprovada" />
                                        
                                    </RadioGroup>
                                )}
                            />
                            {errors.enviou_convite && <span style={{color: "red"}}>Selecione o status da auditoria</span>}
                        </FormControl>
                    </Grid>            
                </Grid>
                <Grid container> 
                    <Grid item xs={4} style={{display: naoAprovado ? "none" : ""}}>
                        <FormControl>
                            <FormLabel>Envio do briefing? *</FormLabel>
                            <Controller
                                name="enviou_briefing"
                                control={control}
                                defaultValue=""
                                rules={{required: false}}
                                render={({field: {onChange, value}}) => (
                                    <RadioGroup
                                        defaultValue="0"
                                        row
                                        onChange={onChange}
                                        value={String(value)}
                                    >
                                        <FormControlLabel disabled={editar} value="1" control={<Radio />} label="Enviado" />
                                        <FormControlLabel disabled={editar} value="0" control={<Radio />} label="Aguardando" />
                                    </RadioGroup>
                                )}
                            />
                            {errors.enviou_briefing && <span style={{color: "red"}}>O campo Envio do briefing é obrigatorio</span>}
                        </FormControl>
                    </Grid>
                </Grid> 
                <Grid item xs={4}>
                     { id ? <Button variant="contained" type="button" style={{marginTop:20}} color="" onClick={() => downloadBrifing(id)}>
                        Baixar briefing
                    </Button> : ""}                
                    

                </Grid>
                <Grid item xs={12}> 
                <hr style={{borderStyle:"solid",borderWidth:0.5}} />
                <br />
                </Grid>
                <Grid container> 
                    <h2>Confirmação</h2>
                </Grid>
                <Grid container> 
                    <Grid item xs={3}>
                        <FormLabel>Data da confirmação</FormLabel>
                        <TextField disabled={editar} fullWidth type={"date"}  variant="standard" {...register("data_confirmacao", {required: false})} />
                        {errors.data_confirmacao && <span style={{color: "red"}}>O campo Data da Confirmação é obrigatorio</span>}
                    </Grid>
                    <Grid item xs={3} style={{marginLeft:10}}>
                        <FormControl fullWidth>
                            <InputLabel>Status *</InputLabel>
                            <Controller
                                name="status"
                                control={control}
                                defaultValue=""
                                rules={{required: false}}
                                render={({field}) => (
                                <Select 
                                {...field} 
                                disabled={editar}
                                onChange={alterarStatus}
                                >
                                    <MenuItem value="aguardando">Aguardando</MenuItem>
                                    <MenuItem value="confirmado">Confirmada</MenuItem>
                                    <MenuItem value="realizada">Realizada</MenuItem>
                                    <MenuItem value="reagendada">Reagendada</MenuItem>
                                    <MenuItem value="declinada">Declinada</MenuItem>
                                    <MenuItem value="aguardando_nova_data">aguardando nova data</MenuItem>
                                    <MenuItem value="nao_aprovada">Não aprovada</MenuItem>
                                </Select>
                                )}/>
                            {errors.status && <span style={{color: "red"}}>O campo Status é obrigatorio</span>}
                        </FormControl>
                    </Grid> 
                </Grid>
                <Grid container>                    
                    <Grid item xs={6}>
                        <Controller 
                            name="observacoes"
                            defaultValue=""
                            control={control}
                            render={({field}) => (
                                <TextField fullWidth disabled={editar} variant="standard" label="Observações" {...field} />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}> 
                    <hr style={{borderStyle:"solid",borderWidth:0.5}} />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <h2>Feedback Cliente:</h2>
                </Grid>
                <Grid item xs={12}>
                    <h3>Interlocutor</h3>
                    <Rating
                        step={1}
                        initialRating={user.feedback_interlocutor}
                        readonly={true}
                    >

                    </Rating>
                    <h3>Assertividade</h3>
                    <Rating
                        step={1}
                        initialRating={user.feedback_assertividade}
                        readonly={true}
                    >

                    </Rating>
                    <h3>Público alvo</h3>
                    <Rating
                        step={1}
                        initialRating={user.feedback_publicoalvo}
                        readonly={true}
                    >

                    </Rating>
                    <h3>Chance de negócio</h3>
                    <Rating
                        step={1}
                        initialRating={user.feedback_chance_negocio}
                        readonly={true}
                    >

                    </Rating>
                    <h3>Gerou Proposta?</h3>
                    <label>{user.feedback_gerou_proposta ? "SIM" : "NÃO"}</label>
                </Grid>

                
                <Grid container style={{marginTop: "30px"}}>
                    <RelatorioLeadModal salvarResumo={salvarResumo} responsibles={leads} />
                    <RelatorioLeadTable deleteResumo={deleteResumo} resumos={resumos}/>
                </Grid>
                <Grid item xs={12}>
                    {!editar && 
                    <Button variant="contained" type="submit" style={{marginTop:20}} color="primary">
                        Salvar
                    </Button>
                    }
                </Grid>
            </Grid>
        </form>
    )
}

export default FormAgendamentoLead;