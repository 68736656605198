import React, {useState} from 'react';

import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles.js'
import {Link, Redirect} from 'react-router-dom';
import Logo from '../../assets/imgs/prospect_brasil.png'
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import { useLocation } from 'react-router-dom';
import ExitToAppIcon    from '@material-ui/icons/ExitToApp';
import api from "../../services/api";
import Container from '@material-ui/core/Container';

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

function SiderBar(props) {
  const { window } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Container>
      <div className={classes.toolbar} style={{textAlign:"center",paddingTop:"5px"}}>
        
        <img src={Logo} width={200}></img>
      </div>
     
      <Divider />
      <List>
        {props.paths.map((path, index) => {
         
         let permissisao = localStorage.getItem(path.local)

          if(permissisao && JSON.parse(permissisao)[path.permissao]) {
            return (
              
                  path.viewMenu == true &&
                  <Link to={path.to} style={{ textDecoration: 'inherit', color:"#000", }}>
                    <ListItem button key={path.text}>
                    
                        <ListItemIcon>
                          {path.icon}
                        </ListItemIcon>
                        <span>{path.text}</span>
                    
                    </ListItem>
                  </Link>
            )
          }
        }
        )}
      </List>
      <Divider />
    </Container>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  const [redirect,setRedirect] = useState(false)

  if(usePathname() !== "/" && usePathname() !== "/login" && usePathname() !== "/politica") {
      api.get("validar-login")
      .catch(function (error) {
        setRedirect(true)
      });

      if(redirect) {
        return <Redirect
        to={{
            pathname: "/login",
        }}
        />
      }
  }



  return (
  
     <>
      {usePathname() !== "/" && usePathname() !== "/login" && usePathname() !== "/politica" ?
        <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
        <CssBaseline />
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            PAINEL ADMINISTRATIVO
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
            <div className={classes.logout}>
              <Link to="/" style={{ textDecoration: 'inherit'}}>
                <ExitToAppIcon/>
              </Link>
            </div>
          </Drawer>
        </Hidden>
        
      </nav>
      <main className={classes.content} id="container-principal" style={{alignItems:"center",display:"grid",paddingLeft:"50px"}}>
        <div className={classes.toolbar}  />
            {props.children}
      </main></div>
        : props.children
      }
      
    </>
  );
}



export default SiderBar;
