import React, {useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast  } from 'react-toastify';
import { TextField, Button } from '@material-ui/core/';
import { LinearProgress } from '@material-ui/core';
import api from '../../services/api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import TableFooter from '@material-ui/core/TableFooter';
import {Link} from 'react-router-dom';
import {StyledTableCell,StyledTableRow, useStyles} from '../mailing/ListingMailing/styles'
import moment from "moment"

const Relatorio = (props) => {

    const [mailings, setMailings] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [dataInicio, setDataInicio] = React.useState("");
    const [dataFim, setDataFim] = React.useState("");
    const [customersSelected, setCustomersSelect] = React.useState(true);
    const [assessorSelect, setAssessorSelect] = useState([]);
    const [mailingSelected, setMailingSelected] = React.useState(true);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = React.useState([]);
    const [totalRows, setTotalRows] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [assessores, setAssessores] = useState([]);
    const [agruparRegiao, setAgruparRegiao] = useState(null);
    const [colunasExtras, setColunasExtras] = useState([]);

    
    const handleMailingSelected = (value) => {
        setMailingSelected(value);
    };

    function handlePage(evt, value) {
        setPage(value);
    }

    const gerar = () => {

        if(!dataInicio) {
            toast.error("Data Início é obrigatório")
            return
        }

        if(!dataFim) {
            toast.error("Data Fim é obrigatório")
            return
        }

        if(!customersSelected.id && !assessorSelect.id) {
            toast.error("Cliente ou Assessor é obrigatório")
            return
        }

        if(dataInicio >= dataFim) {
            toast.error("Data início deve ser menor que Data Fim")
        }

        let dados = {
            data_inicio : dataInicio,
            data_fim : dataFim,
            cliente_id : customersSelected?.id ? customersSelected?.id : null,
            assessor_id : assessorSelect?.id ? assessorSelect?.id : null,
            agrupar_regiao: agruparRegiao ? agruparRegiao.id : null,
            colunas_info_reuniao_exibir: colunasExtras
        }
      
        setLoading(true)

        if(customersSelected?.id || assessorSelect?.id) {
            (async () => {
                const result = await api.get(`relatorio/pdf`, {
                  params: dados,
                  paramsSerializer: params => {
                    return Object.entries(params)
                      .map(([key, value]) => {
                        if (Array.isArray(value)) {
                          return value.map(val => `${key}[]=${val}`).join('&');
                        }
                        return `${key}=${value}`;
                      })
                      .join('&');
                  }
                }).then(res => {
                    setLoading(false)
                    toast.success("Relatorio gerado");
                    getRelatorios()
                })
                .catch(error => {
                    toast.error("Erro ao gerar, tente novamente!");
                    setLoading(false)
                })
            
            })()

         
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const result = await api.get("assessores");
                let data = result.data?.data;
                setAssessores(data);
            } catch (e) {
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.message ? data.message : "Ocorreu um erro ao capturar os dados do mailing";
                toast.error(msg)
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
          const result = await api.get("customers",{ 
            params: { 
              "assessor_id": localStorage.getItem("id_assessor")
            }
          });
          let data = result.data;
          setCustomers(data.data);
        })();
    }, []);

    useEffect(() => { 
        if(customersSelected?.id) {
            (async () => {
                const result = await api.get(`mailings`,{
                  params: { 
                    "assessor_id":  localStorage.getItem("id_assessor"),
                    "customer_id":  customersSelected?.id
                  }
                });
                let data = result.data.data;
                console.log(data)
                setMailings(data)
            })()
        }

    },[customersSelected])

    const getRelatorios = async() => {
        try {
            setLoading(true)

            let url = "relatorio/pdf/listagem?page="+page+"&pageSize=25";
            let result = await api.get(url);
            const totalPerPage = result.data.per_page;
            setTotalRows(Math.round(result.data.total / totalPerPage));
            setRows(result.data.data);
            setLoading(false)

        } catch(exception) {
            toast.error(exception.message);
            setLoading(false)
        }
    }

    useEffect(() => { 
        getRelatorios()
    },[]);

    useEffect(() => { 
        getRelatorios()
    },[page]);

    // Adicione esta função para converter os IDs em objetos completos
    const getSelectedOptions = () => {
        const options = [
            { id: 'site', label: 'Site' },
            { id: 'segmento', label: 'CNAE' },
            { id: 'cnpj', label: 'CNPJ' }
        ];
        
        return colunasExtras.map(id => 
            options.find(option => option.id === id)
        ).filter(Boolean);
    };

    return <>
        <Container fixed>
            <div>
                <h1> { "Relatorios" } </h1>
            </div>
            <div
                style={{
                borderRadius: "5px",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                backgroundColor:"#fff",
                padding:"10px"
                }}
            >
                <Grid container  spacing={2}>
                    <Grid item xs={4}>
                        <Autocomplete
                            disablePortal
                            id="Assessor"
                            options={assessores}
                            onChange={(event, newInputValue) => {
                                setAssessorSelect(newInputValue);
                            }}
                            value={assessorSelect}
                            getOptionLabel={(option) => option.name || ""}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Assessor" />}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            disablePortal
                            id="Cliente"
                            options={customers}
                            onChange={(event, newInputValue) => {
                                setCustomersSelect(newInputValue);
                            }}
                            value={customersSelected}
                            getOptionLabel={(option) => option.name || ""}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Cliente" />}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            disablePortal
                            id="Mailing"
                            options={mailings}
                            onChange={(event, newInputValue) => {
                                handleMailingSelected(newInputValue);
                            }}
                            value={mailingSelected}
                            getOptionLabel={(option) => option.name || ""}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Mailing" />}
                        />

                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginTop: "15px"}}>
                <Grid container spacing={2} style={{marginTop: "15px"}}>
                    <Grid item xs={4}>
                        <TextField  
                            style={{marginLeft: "5px"}}
                            id="data_inicio"
                            label="Data Início"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            value={dataInicio} 
                            type={"date"}
                            onChange={
                            evt => setDataInicio(evt.target.value)
                            } fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField  
                            style={{marginLeft: "5px"}}
                            id="data_fim"
                            label="Data Fim"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            value={dataFim} 
                            type={"date"}
                            onChange={
                            evt => setDataFim(evt.target.value)
                            } fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            id="agrupar-regiao"
                            options={[
                                { id: 'cidade', label: 'Cidade' },
                                { id: 'estado', label: 'Estado' },
                                { id: 'cidade_estado', label: 'Cidade - Estado' },
                                { id: 'pais', label: 'País' },
                                { id: 'completo', label: 'Completo' }
                            ]}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            getOptionLabel={(option) => option.label || ""}
                            onChange={(event, newValue) => {
                                setAgruparRegiao(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Agrupar Região por:"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginTop: "15px"}}>
                    <Grid item xs={4}>
                        <Autocomplete
                            multiple
                            id="colunas-extras"
                            options={[
                                { id: 'site', label: 'Site' },
                                { id: 'segmento', label: 'CNAE' },
                                { id: 'cnpj', label: 'CNPJ' }
                            ]}
                            value={getSelectedOptions()}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setColunasExtras(newValue.map(item => item.id));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    style={{marginLeft: "5px"}}
                                    label="Exibir colunas Extras em Info. Reunião"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                </Grid>
                <Grid container style={{marginTop:"15px"}}  spacing={2}>
                <Grid item xs={5}>
                    <Button 
                    variant="contained" 
                    style={{color:"#4B8B33"}} 
                    onClick={() => { gerar() }}
                    href="#contained-buttons"
                    disabled={loading}
                    >
                    GERAR RELATORIO
                    </Button>
                </Grid>
                </Grid>     
            </div>
            {loading && <div style={{width: "100%"}}>
                <LinearProgress/>
            </div>}

            <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Razão Social</StyledTableCell>
                            <StyledTableCell align="center">Assessor</StyledTableCell>
                            <StyledTableCell align="center">Data Início</StyledTableCell>
                            <StyledTableCell align="center">Data Fim</StyledTableCell>
                            <StyledTableCell align="center">Baixa</StyledTableCell>
                        </TableRow>
                    </TableHead>
                <TableBody>
                    {rows.map( (row, key) => (
                    <StyledTableRow key={row.id} id={"relatorio-" +key}>
                        <StyledTableCell align="center">{row.cliente?.corporate_name}</StyledTableCell>
                        <StyledTableCell align="center">{row.assessor?.name}</StyledTableCell>
                        <StyledTableCell align="center">{moment(row.data_inicio, "YYYY-MM-DD").format("DD/MM/YYYY")}</StyledTableCell>
                        <StyledTableCell align="center">{moment(row.data_fim, "YYYY-MM-DD").format("DD/MM/YYYY")}</StyledTableCell>
                        <StyledTableCell align="center">
                        <a href={"https://api-crm.prospectbrasil.com.br/api/relatorio/pdf/download?path=" + row.path}>Baixar</a>
                      </StyledTableCell>
                    </StyledTableRow>
                    ))}
                </TableBody>
                <TableFooter>
                <div style={{textAlign:"center",paddingTop:"10px",paddingBottom:"10px"}}>
                    <Pagination style={{textAlign:"center"}} count={totalRows} page={page} onChange={handlePage} />

                </div>
                </TableFooter>
            </Table>
        </Container>
   </>

}

export default Relatorio;