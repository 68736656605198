import {
    TableContainer, 
    Table, 
    TableHead, 
    TableRow, 
    TableCell, 
    Paper,
    TableBody,Button
} from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';

export default (props) => {

 

    function formatarData (data) {
        if(!data) {
            return ""
        }

        var datePart = data.match(/\d+/g),
        year = datePart[0].substring(2), // get only two digits
        month = datePart[1], day = datePart[2];
      
        return day+'/'+month+'/'+year;
      }

    
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Mailing</TableCell>
                            <TableCell align="center">Resumo</TableCell>
                            <TableCell align="center">Data</TableCell>
                            <TableCell align="center">Decisor</TableCell>
                            <TableCell align="center">Follow-up</TableCell>
                            <TableCell align="center">Assessor</TableCell>
                            <TableCell align="center">Terceirização</TableCell>
                            <TableCell align="center">Data de Bid</TableCell>
                            <TableCell align="center">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                    {props.resumos.map(resumo => {
                            return (
                                <TableRow key={resumo.id}>
                                    <TableCell align="center">{resumo.mailing_lead?.mailing?.name}</TableCell>
                                    <TableCell align="center">{resumo.resumo}</TableCell>
                                    <TableCell align="center">{resumo.data ? formatarData(resumo.data) : null}</TableCell>
                                    <TableCell align="center">{resumo.responsible?.name}</TableCell>
                                    <TableCell align="center">{resumo.follow_up ? formatarData(resumo.follow_up): null}</TableCell>
                                    <TableCell align="center">{resumo.assessor?.name}</TableCell>
                                    <TableCell align="center">{resumo.terceirizacao}</TableCell>
                                    <TableCell align="center">{resumo.data_bid}</TableCell>

                                    
                                    <TableCell align="center">
                                        <Button onClick={() => props.deleteResumo(resumo.id)}>
                                            <IconButton
                                            size="small"
                                            aria-label="delete"
                                            >
                                            <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}