import { useEffect, useState } from "react";
import api, { baseURL } from '../../../services/api.js';
import { Container, Table, TableHead, TableBody, TableFooter, TableRow , Grid, TextField} from "@material-ui/core";
import {StyledTableCell,StyledTableRow, useStyles} from '../../mailing/ListingMailing/styles'
import { toast } from 'react-toastify';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { LinearProgress } from '@material-ui/core';
import InputMask from 'react-input-mask';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import IconButton from '@material-ui/core/IconButton';
import * as axios from 'axios';


export default () => {
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [corporateName,setCorporateName] = useState(null)
    const [cnpj,setCnpj] = useState("")
    const [loading, setLoading] = useState(true);
    const [permissisao,setPermissisao] = useState(JSON.parse(localStorage.getItem("leads")));
    const [permissisaoMailing,setPermissisaoMailing] = useState(JSON.parse(localStorage.getItem("mailing")));
    const [permissisaoUsers,setPermissisaoUsers] = useState(JSON.parse(localStorage.getItem("usuarios")));
    const [permissisaoClientes,setPermissisaoClientes] = useState(JSON.parse(localStorage.getItem("clientes")));

    const [confirmOpen,setConfirmOpen] = useState(false);
    const [deletedID,setDeletedID] = useState(null);


    const handleCorporateName = (value) => {
        setCorporateName(value)
    };

    const handleCnpj = (value) => {
        setCnpj(value)
    };
      
    useEffect(async () => {
        try {
            getLeads()
        } catch(Ex) {
            toast.error(Ex.message);
        }
    }, []);


    useEffect(async () => {
        try {
            getLeads()
        } catch(Ex) {
            toast.error(Ex.message);
        }
    }, [page]);

    const getLeads = async() => {
        try {
            setLoading(true)

            let url = "leads?page="+page+"&pageSize=25";
            let result = await api.get(url,{
                params: {
                    corporate_name:corporateName,
                    cnpj:cnpj                  
                }
            });
            const totalPerPage = result.data.per_page;
            setTotalRows(Math.round(result.data.total / totalPerPage));
            setRows(result.data.data);
            setLoading(false)

        } catch(exception) {
            toast.error(exception.message);
            setLoading(false)
        }
    }

    function handlePage(evt, value) {
        setPage(value);
    }


    const ConfirmDialog = (props) => {
        const { title, children, open, setOpen, onConfirm } = props;
        return (
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
          >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setOpen(false)}
                color="secondary"
              >
                Não
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(false);
                  onConfirm();
                }}
                color="default"
              >
                Sim
              </Button>
            </DialogActions>
          </Dialog>
        );
      };

    const handleDeleteClick = () => {
        

        api.delete(`lead/${deletedID}`).then(res => {
            getLeads()
            toast.success("Lead excluído");

        }).catch(error => {
            toast.error("Erro ao excluir");

            console.log(error)
        })
       
    };

    const exportLeads = async () => {
        setLoading(true)

        await axios({
            url: `${baseURL}lead-export`, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
        
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'leads.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);

            setLoading(false)

        }).catch( () =>{
            setLoading(false)
        });

     

    }

    return (
        <>
            <Container fixed>
                <div>
                    <h1>Leads</h1>
                </div>
                <div
                  style={{
                    borderRadius: "5px",
                    borderLeftWidth: "1px",
                    borderRightWidth: "1px",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    backgroundColor:"#fff",
                    padding:"10px"
                  }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <InputMask mask="99.999.999/9999-99" value={cnpj} onChange={(evt) => handleCnpj(evt.target.value)}>
                                {(inputProps) => <TextField {...inputProps}   label="CNPJ" />}
                            </InputMask>
                   
                        </Grid>
                        <Grid item xs={6}>
                            <TextField  
                            style={{marginLeft: "5px"}}
                            id="corporate_name"
                            label="Razão Social"
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                            value={corporateName} 
                            type={"text"}
                            onChange={
                                evt => handleCorporateName(evt.target.value)
                            } fullWidth /> 
                        </Grid>
                        <Grid container style={{marginTop:"15px"}} >
                            <Grid item xs={5}>
                            <Button 
                                variant="contained" 
                                style={{color:"#4B8B33"}} 
                                onClick={() => { getLeads() }}
                                href="#contained-buttons"
                                >
                                PESQUISAR
                             </Button>
                        </Grid>
                      </Grid>
                  </Grid>
                </div>


               
                    <div style={{paddingBottom: "10px", paddingTop: "10px"}}>
                    {
                        permissisao.write ? 
                        <Link to="/leads/novo" style={{ textDecoration: 'inherit'}}>
                            <Button variant="contained" style={{color:"#4B8B33"}} href="#contained-buttons">
                                NOVO
                            </Button>
                        </Link> : ""
                     
                        
                    }
                    {
                           permissisao.write ?
                           <Button variant="contained" style={{color:"#4B8B33", marginLeft: "10px"}} onClick={() => exportLeads()}>
                               EXPORTAR
                           </Button> : ""
                    }
                    {
                        permissisaoMailing.write && <Link to="/leads/importacao" style={{ textDecoration: 'inherit'}}>
                        <Button variant="contained" style={{color:"#4B8B33", marginLeft: "10px"}}  href="#contained-buttons">
                            IMPORTAR
                        </Button></Link>
                    }
                    </div> 

           
                  {loading && <div style={{width: "100%"}}>
                <LinearProgress/>
            </div>}
            

                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Razão Social</StyledTableCell>
                            <StyledTableCell align="center">CNPJ</StyledTableCell>
                            <StyledTableCell align="center">CNAE</StyledTableCell>
                            <StyledTableCell align="center">Ações</StyledTableCell>
                        </TableRow>
                    </TableHead>
                <TableBody>
                    {rows.map( (row, key) => (
                    <StyledTableRow key={row.id} id={"lead-" +key}>
                        <StyledTableCell align="center">{row.corporate_name}</StyledTableCell>
                        <StyledTableCell align="center">{row.cnpj}</StyledTableCell>
                        <StyledTableCell align="center">{row.cnae}</StyledTableCell>
                        <StyledTableCell align="center">
                        {
                            permissisao.write ? 
                            <Link to={"/leads/"+row.id+"/edit"} style={{ textDecoration: 'inherit'}}>
                                <IconButton>
                                    <EditIcon/>
                                </IconButton>
                            </Link> : ""
                        }
                            {
                            permissisao.write && permissisaoUsers.write && permissisaoMailing.write && permissisaoClientes.write ? 
                            <IconButton
                            color="inherit"
                            size="small"
                            aria-label="delete"
                            onClick={() => {
                                setConfirmOpen(true)
                                setDeletedID(row.id)
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton> : ""
                        }
                      </StyledTableCell>
                    </StyledTableRow>
                    ))}
                </TableBody>
                <TableFooter>
                <div style={{textAlign:"center",paddingTop:"10px",paddingBottom:"10px"}}>
                    <Pagination style={{textAlign:"center"}} count={totalRows} page={page} onChange={handlePage} />

                </div>
                </TableFooter>
            </Table>
            <ConfirmDialog
                title="Excluir?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleDeleteClick}
            >
                Tem certeza de que deseja excluir?
            </ConfirmDialog>
            </Container>
        </>
    )
}