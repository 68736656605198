import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import TableFooter from '@material-ui/core/TableFooter';
import {TextField, Button,  Select, MenuItem,InputLabel } from '@material-ui/core';
import {Link} from 'react-router-dom';
import {StyledTableCell,StyledTableRow, useStyles} from './styles.js'
import Container from '@material-ui/core/Container';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import api from '../../../services/api.js';
import { toast } from 'react-toastify';
import moment from "moment"
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { LinearProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

function createData(corporate_name, cnpj) {
  return { corporate_name, cnpj };
}

// const rows = [
//   {
//     "id":1,
//     "corporate_name":"Empresa 1",
//     "cnpj":"21.854.634/0001-81"
//   },
//   {
//     "id":2,
//     "corporate_name":"Empresa 2",
//     "cnpj":"27.048.849/0001-91"
//   }
// ];


export default function ListingCustomer() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [name,setName] = useState("");
  const [corporateName,setCorporateName] = useState("");
  const [segmento, setSegmento] = useState("");
  const [status,setStatus] = useState({"nome": "Ativo", "status": true});
  const [errorState,setErrorState] = useState(false);
  const [permissisao,setPermissisao] = useState(JSON.parse(localStorage.getItem("clientes")));
  const [permissisaoUsers,setPermissisaoUsers] = useState(JSON.parse(localStorage.getItem("usuarios")));

  const [loading, setLoading] = React.useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [confirmOpen,setConfirmOpen] = useState(false);
  const [deletedID,setDeletedID] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleName = (event) => {
    setName(event.target.value)
  };

  const handleCorporateName = (event) => {
    setCorporateName(event.target.value)
  };


  const handleStatus = (value) => {
    setStatus(value)
  };

  const handleSegmento = (event) => {
    setSegmento(event.target.value);
  }

  useEffect(() => {
    (async () => {
        const result = await api.get(`customers?page=${page}&pageSize=25`,{
          params: {
            name,
            corporate_name:corporateName,
            status:status.status,
            segmento: segmento
          }
        })
        const data = result?.data?.data
        const totalPerPage = result.data.per_page;
        setTotalRows(Math.ceil(result.data.total / totalPerPage));
        setRows(data)
        setLoading(false)
    })()
  },[page]);

  const search = async() => {
    setRows([])
    setLoading(true)
    const result = await api.get(`customers?page=${page}`,{
      params: {
        name,
        status:status.status,
        segmento: segmento
      }
    })
    const data = result?.data?.data
    setRows(data)
    setLoading(false)
  }

  function handlePage(evt, value) {
    setPage(value);
  }


  const ConfirmDialog = (props) => {
    const { title, children, open, setOpen, onConfirm } = props;
    return (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            color="secondary"
          >
            Não
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            color="default"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleDeleteClick = () => {
      

      api.delete(`customers/${deletedID}`).then(res => {
          search()
          toast.success("Cliente excluído");

      }).catch(error => {
          toast.error("Erro ao excluir");

          console.log(error)
      })
    
  };


  return (
    <>
   
      <Container fixed>

          <div>
              <h1>Clientes</h1>
          </div>


          <div
            style={{
              borderRadius: "5px",
              borderLeftWidth: "1px",
              borderRightWidth: "1px",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              backgroundColor:"#fff",
              padding:"10px"
            }}
          >
            <Grid container  spacing={2}>
              <Grid item xs={4}>
                <TextField  id="standard-required" label="Nome fantasia" value={name} onChange={handleName} fullWidth />
              </Grid>
              <Grid item xs={4}>
                <TextField  id="standard-required" label="Segmento" value={segmento} onChange={handleSegmento} fullWidth />
              </Grid>
              <Grid item xs={2}>

                <Autocomplete
                      disablePortal
                      
                      id="state"
                      options={ [
                        {"nome": "", "status": ""},
                        {"nome": "Ativo", "status": true},
                        {"nome": "Inativo", "status": false}
                      ]}
                      onChange={(event, newInputValue) => {
                          handleStatus(newInputValue);
                      }}
                      value={status}
                      getOptionLabel={(option) => option.nome || ""}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params}  label="Status" required 
                    />}
                />
          </Grid>
            </Grid>
            <Grid container style={{marginTop:"15px"}}  spacing={2}>
              <Grid item xs={5}>
                <Button 
                variant="contained" 
                style={{color:"#4B8B33"}} 
                onClick={() => { search() }}
                href="#contained-buttons"
                >
                  PESQUISAR
                </Button>
              </Grid>
            </Grid>
          </div>
          <br /><br />
          {
          permissisao.write ? 
             <div style={{paddingBottom:"10px"}}>
             <Link to="/customer/new" style={{ textDecoration: 'inherit'}}>
                 <Button variant="contained" style={{color:"#4B8B33"}} href="#contained-buttons">
                     NOVO
                 </Button>
             </Link>
       
          </div>
          : ""
        
          }
       
        
          
          <TableContainer component={Paper}>
          {loading && <div style={{width: "100%"}}>
            <LinearProgress/>
          </div>}

          <Table className={classes.table} aria-label="customized table">
              <TableHead>
              <TableRow>
                  <StyledTableCell align="center">Nome</StyledTableCell>
                  <StyledTableCell align="center">Segmento</StyledTableCell>
                  <StyledTableCell align="center">Razão Social</StyledTableCell>
                  <StyledTableCell align="center">CNPJ</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Criado em</StyledTableCell>
                  <StyledTableCell align="center">Ações</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {rows.map((row) => (
                  <StyledTableRow key={row.id}>
                      <StyledTableCell align="center" component="th" scope="row">
                          {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.segmento}</StyledTableCell>
                      <StyledTableCell align="center">{row.corporate_name}</StyledTableCell>
                      <StyledTableCell align="center">{row.cnpj}</StyledTableCell>
                      <StyledTableCell align="center">{row.active ? 'Ativo' : 'Inativo'}</StyledTableCell>
                      <StyledTableCell align="center">{moment(row.created_at, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm")}</StyledTableCell>
                      <StyledTableCell align="center">
                        {permissisao.write ?
                        <Link to={"/customer/"+row.id+"/edit"} style={{ textDecoration: 'inherit'}}>
                          <IconButton>
                              <EditIcon/>
                          </IconButton>
                        </Link>: ""
                        }
                                          {
                            permissisao.write && permissisaoUsers.write && permissisaoUsers.write ? 
                            <IconButton
                            color="inherit"
                            size="small"
                            aria-label="delete"
                            onClick={() => {
                                setConfirmOpen(true)
                                setDeletedID(row.id)
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton> : ""
                        }
                      </StyledTableCell>
                      
                  </StyledTableRow>
              ))}
              </TableBody>
              <TableFooter>
              <div style={{textAlign:"center",paddingTop:"10px",paddingBottom:"10px"}}>
                  <Pagination style={{textAlign:"center"}} count={totalRows} page={page} onChange={handlePage} />
              </div>
              </TableFooter>
          </Table>
          </TableContainer>
      
          <ConfirmDialog
                title="Excluir?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleDeleteClick}
            >
                Tem certeza de que deseja excluir?
            </ConfirmDialog>
      </Container>
    </>
  );
}
