import React, { useState,useMemo, useEffect , useRef } from 'react';
import Container from '@material-ui/core/Container';
import {TextField, Button,  Dialog, DialogActions, DialogContent, DialogTitle, Box} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import InputMask from 'react-input-mask';
import { DataGrid,GridToolbarContainer,useGridApiRef,ptBR  } from '@mui/x-data-grid';
import jsonContacts from "../contatos.json";
import jsonSectors from "../setores.json";

import {useStyles} from "./styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { ContactSupportOutlined, RadioTwoTone } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import {randomId} from '@mui/x-data-grid-generator';
import {useParams, useHistory} from "react-router-dom";
import { toast  } from 'react-toastify';
import api from '../../../services/api';
import ImageUploading from 'react-images-uploading';
import blackProfile from '../../../assets/imgs/blank-profile.png'
import Typography from '@material-ui/core/Typography';


function RowMenuCell(props) {
    const { api, id } = props;
    const classes = useStyles();
  
    const handleDeleteClick = (event) => {
      event.stopPropagation();
      //console.log(event)
      api.updateRows([{ id, _action: 'delete' }]);
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          color="inherit"
          size="small"
          aria-label="delete"
          onClick={handleDeleteClick}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
    );
}

var senhas = []

function RowPassword(props) {
    const { api, id } = props;
    console.log(senhas)
    return (
        <TextField
            type="password"
            value={props.value}
            onChange={e => {
                let newSenhas = senhas;
                console.log(e.target.value)
                senhas = {...newSenhas,[id]:e.target.value}
            }}
        />
    );
}



const columnsContact = [
    { field: 'name', headerName: 'Name', width: 400, editable: true},
    { field: 'email', headerName: 'E-mail', width: 200, editable: true },
    { field: 'telephone', headerName: 'Telefone', width: 150, editable: true },
    { field: 'role', headerName: 'Cargo', width: 150, editable: true },
    { field: 'sector', headerName: 'Setor', width: 150, editable: true },
    { field: 'access_app',type:"boolean", headerName: 'Acesso', width: 150, editable: true },
    
    { 
        field: 'senha_app',
        headerName: 'Senha',  
        width: 150,
        editable: true,
        // renderCell: RowPassword,
        // valueGetter: (params) => params.row.title
    },
    {
        field: 'actions',
        headerName: 'Ações',
        renderCell: RowMenuCell,
        sortable: false,
        width: 100,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true,
      },
];


const columnsSector = [
    { field: 'name', headerName: 'Name', width: 400, editable: true},
    {
        field: 'actions',
        headerName: 'Ações',
        renderCell: RowMenuCell,
        sortable: false,
        width: 100,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true,
      },
];


function useApiRef() {
    const apiRef = useGridApiRef();
    const _columns = useMemo(
      () =>
      columnsContact.concat({
          field: "__HIDDEN__",
          width: 0,
          renderCell: (params) => {
            apiRef.current = params.api;
            return null;
          }
        }),
      [columnsContact]
    );
  
    return { apiRef, columnsContact: _columns };
}

function useApiRefSector() {
    const apiRefSector = useGridApiRef();
    const _columns = useMemo(
      () =>
      columnsSector.concat({
          field: "__HIDDEN__",
          width: 0,
          renderCell: (params) => {
            apiRefSector.current = params.api;
            return null;
          }
        }),
      [columnsSector]
    );

    // setores.forEach((col)=>{
    //     apiRefSector.current.setRowMode(col.id, 'edit');
    // })
  
    return { apiRefSector, columnsSector: _columns };
}




const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const FormCustomer = (props) => {

    const classes = useStyles();

    const history = useHistory()
    let { id } = useParams();
     
    const [active,setActive] = useState(false)
    const [name,setName] = useState("")

    const [corporateName,setCorporateName] = useState("")

    const [cnpj,setCnpj] = useState("")
    const [cep,setCep] = useState("")
    const [logradouro,setLogradouro] = useState("")
    const [neighborhood,setNeighborhood] = useState("")
    const [number,setNumber] = useState("")
    const [complement,setComplement] = useState("")
    const [city,setCity] = useState("")
    const [state,setState] = useState("")
    const [telephone,setTelephone] = useState("")
    const [segmento, setSegmento] = useState("");
    const [open, setOpen] = useState(false);
    const [mailingCurrent, setMailingCurrent] = useState(null);

    const [usersSelected, setUsersSelected] = useState([]);

    const [users,setUsers] = useState([])
    const [contactsModel, setContactsModel] = React.useState({});
    const [mailings,setMailings] = useState([])
    const [mailingsSelected,setMailingsSelected] = useState([])
    
    const [contacts, setContacts] = useState([]);
    const [images, setImages] = React.useState([]);
    const [imageSaved, setimageSaved] = React.useState([]);

    const maxNumber = 69;
  

  

    const [errorLogradouro,setErrorLogradouro] = useState(false)
    const [errorLogradouroMsg,setErrorLogradouroMsg] = useState("")
    const [errorNeighborhood,setErrorNeighborhood] = useState(false)
    const [errorNeighborhoodMsg,setErrorNeighborhoodMsg] = useState("")
    const [errorCity,setErrorCity] = useState(false)
    const [errorCityMsg,setErrorCityMsg] = useState("")
    const [errorState,setErrorState] = useState(false)
    const [errorStateMsg,setErrorStateMsg] = useState("")

    const [errorCorporateName,setErrorCorporateName] = useState(false)
    const [errorCorporateNameMsg,setErrorCorporateNameMsg] = useState("")


    const [errorName,setErrorName] = useState(false)
    const [errorNameMsg,setErrorNameMsg] = useState("")
    

    const [errorCnpj,setErrorCnpj] = useState(false)
    const [errorCnpjMsg,setErrorCnpjMsg] = useState("")

    const { apiRef, columnsContact } = useApiRef();

    function setPasswordContact(value) {
        console.log(value)
    }
    
    function EditToolbar(props) {
        const { apiRef, labelButton  } = props;
        const handleClick = () => {
            const id_row = "new_"+randomId();
            let rowsArr = [...contacts];   

            rowsArr.push({ id: id_row })
            setContacts(rowsArr)
        };
     
      
    
        return (
          <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
              {labelButton}
            </Button>
          </GridToolbarContainer>
        );
    }

    useEffect(() => {
        (async () => {
            if(id){
                const result = await api.get(`customer/${id}`)
                const data = result?.data
                console.log("data",data)
                setActive(data.active)
                setName(data.name)
                setCorporateName(data.corporate_name)
                setCnpj(data.cnpj)
                setCep(data.zip_code)
                setLogradouro(data.logradouro)
                setNeighborhood(data.neighborhood)
                setComplement(data.complement)
                setCity(data.city)
                setTelephone(data.telephone)

                let state = null;
                UFS.map((uf)=>{
                    if(uf.sigla === data.state){
                        state = uf
                    }
                })
             
                setState(state)
                setNumber(data.number)
                setUsersSelected(data.assessores);
                setMailingsSelected(data.mailings);
                setContacts(data.contacts)
                setSegmento(data.segmento);

                if(data.image){
                    setImages([{"data_url":data.image}])
                }
                

            }else{
                let newId= "new_"+randomId();
                setContacts([{id:newId}])
            }
        })()
    },[]);
    
    useEffect(() => {
        (async () => {
            const result = await api.get('assessores')
            const data = result?.data?.data
            setUsers(data);
        })()
    },[]);

    useEffect(() => {
        (async () => {
            const result = await api.get('mailings')
            const data = result?.data?.data
            setMailings(data);
        })()
    },[]);

    const UFS =  [
        {"nome": "", "sigla": ""},
        {"nome": "Acre", "sigla": "AC"},
        {"nome": "Alagoas", "sigla": "AL"},
        {"nome": "Amapá", "sigla": "AP"},
        {"nome": "Amazonas", "sigla": "AM"},
        {"nome": "Bahia", "sigla": "BA"},
        {"nome": "Ceará", "sigla": "CE"},
        {"nome": "Distrito Federal", "sigla": "DF"},
        {"nome": "Espírito Santo", "sigla": "ES"},
        {"nome": "Goiás", "sigla": "GO"},
        {"nome": "Maranhão", "sigla": "MA"},
        {"nome": "Mato Grosso", "sigla": "MT"},
        {"nome": "Mato Grosso do Sul", "sigla": "MS"},
        {"nome": "Minas Gerais", "sigla": "MG"},
        {"nome": "Pará", "sigla": "PA"},
        {"nome": "Paraíba", "sigla": "PB"},
        {"nome": "Paraná", "sigla": "PR"},
        {"nome": "Pernambuco", "sigla": "PE"},
        {"nome": "Piauí", "sigla": "PI"},
        {"nome": "Rio de Janeiro", "sigla": "RJ"},
        {"nome": "Rio Grande do Norte", "sigla": "RN"},
        {"nome": "Rio Grande do Sul", "sigla": "RS"},
        {"nome": "Rondônia", "sigla": "RO"},
        {"nome": "Roraima", "sigla": "RR"},
        {"nome": "Santa Catarina", "sigla": "SC"},
        {"nome": "São Paulo", "sigla": "SP"},
        {"nome": "Sergipe", "sigla": "SE"},
        {"nome": "Tocantins", "sigla": "TO"}
    ];


    const handleImage = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    const handleActive = (event) => {
        setActive(event.target.checked)
    };


    const handleName = (event) => {
        setName(event.target.value)
    };


    const handleCorporateName = (event) => {
        setCorporateName(event.target.value)
    };


    const handleCnpj = (event) => {
        setCnpj(event.target.value.replace(/[^0-9]/g, ''))
    };

    const handleCep = (event) => {
        let cep = event.target.value.replace(/[^0-9]/g, '')
        setCep(cep)
        if(cep.length === 8){
            fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then((res) => res.json())
            .then((data) => {

                if(data.erro && data.erro == true){
                    return false;
                }
    
                setLogradouro(data.logradouro)
                setNeighborhood(data.bairro)
                setCity(data.localidade)
                setState(data.uf)
    
            }).catch((error) =>{
                alert("Erro")
            });
        }

    };

    const handleLogradouro = (event) => {
        setLogradouro(event.target.value)
    };

    const handleNumber = (event) => {
        setNumber(event.target.value.replace(/[^0-9]/g, ''))
    };


    
    const handleComplement = (event) => {
        setComplement(event.target.value)
    };


    const handleNeighborhood = (event) => {
        if(event.target.value){
            setErrorNeighborhood(false)
            setErrorNeighborhoodMsg("")
        }

        setNeighborhood(event.target.value)
    };

    const handleCity = (event) => {
        setCity(event.target.value)
    };

    const handleState = (value) => {
        // console.log(value)
        setState(value)
    };

    const handleTelephone = (event) => {
        let tel = event.target.value.replace(/[^0-9]/g, '')
        setTelephone(tel)
    }


    const handleUserSelected = (value) => {
        setUsersSelected(value);
    };

    const handleManterEspelhado = () => {
        setMailingsSelected(mailingCurrent);
        handleClose()
        toast.success("As alterações neste mailing serão refletidas em todos os clientes aos quais ele pertence.")
    }

    const [errorNomeMailing,setErrorNomeMailing] = useState(false)
''
    const [errorNomeMailingMsg,setErroromeMailingMsg] = useState("")

    
    const  handleCopiar = async (mailingNewName) => {

        if(!mailingNewName) {
            setErrorNomeMailing(true)
            setErroromeMailingMsg("Defina um novo nome para o Mailing para copia-lo")
            return
        }
    
    
        let inactiveObject = mailingCurrent[mailingCurrent.length - 1];

        let data = {
            id: inactiveObject.id,
            name: mailingNewName
        }
        const result = await api.post("/mailings/copy",data)
     

        setMailingsSelected([...mailingsSelected, {id:result.data.mailing.id, name: mailingNewName}])
        handleClose()
        // setMailingsSelected()
        // console.log(inactiveObject);
        // mailingsSelected,setMailingsSelected
        // const result = await api.post("/customer/save",data)

        toast.success("Mailing copiado com sucesso! Agora você pode salvar o cliente com o novo mailing.")

    }

    const handleMailingSelected = async (nome, value) => {
       


        if(nome) {
            
            let ultimoObjeto = value[value.length - 1];

            setMailingCurrent(value)


            const resultMailingCopy = await api.get(`mailings/${ultimoObjeto.id}/cliente/${id}`)
    
            if(resultMailingCopy.data.length > 0) {
                handleOpen()
            }else{
                setMailingsSelected(value);
            }
        }else{
            setMailingsSelected(value);
        }

    };

    const validationFields = async () => {   
        let result = true

        if(!name) {
            setErrorName(true);
            setErrorNameMsg("Campo Obrigátorio")
            result = false
        }

        if(!corporateName) {
            setErrorCorporateName(true);
            setErrorCorporateNameMsg("Campo Obrigátorio")
            result = false
        }


        if(!cnpj) {
            setErrorCnpj(true);
            setErrorCnpjMsg("Campo Obrigátorio")
            result = false
        }


        

        if(!logradouro){
            setErrorLogradouro(true)
            setErrorLogradouroMsg("Campo Obrigátorio")
            result = false
        }

        if(!neighborhood){
            setErrorNeighborhood(true)
            setErrorNeighborhoodMsg("Campo Obrigátorio")
            result = false
        }

        if(!city){
            setErrorCity(true)
            setErrorCityMsg("Campo Obrigátorio")
            result = false
        }
 
        if(!state){
            setErrorState(true)
            setErrorStateMsg("Campo Obrigátorio")
            result = false
        }
        return result
    }

    const clearErrorsFields = () => {  

        setErrorLogradouro(false)
        setErrorLogradouroMsg("")

        setErrorNeighborhood(false)
        setErrorNeighborhoodMsg("")

        setErrorCity(false)
        setErrorCityMsg("")

        setErrorState(false)
        setErrorStateMsg("")
    }


    const handleSave = async () => {

        let validation = await validationFields()
        if(validation){
            clearErrorsFields();
            let contacts = []
            try {
                contacts = apiRef.current.getRowModels();
            } catch (error) {
            }
           
    
    
            let contactsArr = [];   
            contacts.forEach((contact) =>{
                contactsArr.push({
                    "id": contact.id,
                    "name": contact.name,
                    "email": contact.email,
                    "access_app":contact.access_app,
                    "telephone": contact.telephone,
                    "role": contact.role,
                    "sector": contact.sector,
                    "password": contact.senha_app,

                })
            })
            
            let assessores = [];
            usersSelected.forEach((user) =>{
                assessores.push(user.id)
            })


            let mailings = [];
            mailingsSelected.forEach((mailing) =>{
                mailings.push(mailing.id)
            })
       
            let data = {
                image:images.length > 0 ? images[0]["data_url"] : null,
                active,
                name,
                cnpj,
                corporate_name:corporateName,
                zip_code:cep,
                logradouro,
                number,
                complement,
                neighborhood,
                city,
                state: state.sigla,
                telephone,
                assessores: assessores,
                mailings: mailings,
                contacts: contactsArr,
                segmento
            };
            

            try {
                if(id){
                    await api.patch(`/customer/${id}/save`,data)
                    toast.success("Cliente editado com sucesso")
                    
                } else {
                    const result = await api.post("/customer/save",data)
                    toast.success("Cliente cadatrado com sucesso")
                    const id_created = (result.data?.data?.id)
                    history.replace(`/customer/${id_created}/edit`)
                }
              
            }catch(e){
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.message ? data.message : "Ocorreu um erro ao salvar o usuário";
                toast.error(msg)
            }
            
        }

    };

    const handleEditRowsModelChange = React.useCallback((newModel) => {
        const currentKey = Object.keys(newModel)[0]
        const currenctContactIndex = contacts.findIndex((item) => item.id == currentKey)
        const currenctContact = [...contacts]
        if(currenctContactIndex > -1){
            console.log(newModel[currentKey])
            const mapValue = {}
            Object.keys(newModel[currentKey]).map((item) => { 
                 mapValue[item] = newModel[currentKey][item].value
            })
            currenctContact[currenctContactIndex] = { ...contacts[currenctContactIndex], ...mapValue  }
            setContacts(currenctContact)
        }
   
        setContactsModel({...newModel});
      }, [contacts]);

    const handleSegmento = (event) => {
        setSegmento(event.target.value);
    }



    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleCopyLeads = () => {
      // Adicione sua lógica para copiar leads aqui
      console.log('Copiar Leads');
      handleClose();
    };
  
    const handleMirror = () => {
      // Adicione sua lógica para manter espelhado aqui
      console.log('Manter Espelhado');
      handleClose();
    };

 


    // useEffect(() => {
    //     if (open && inputRef.current) {
    //         // Foca no input ao abrir a modal
    //         inputRef.current.focus();
    //     }
    // }, [open]);


    const ModalMailingCopia = ({handleCopiar, handleManterEspelhado, handleClose}) => {
        
        const [mailingNewName,setMailingNewName] = useState("")

        const handleChangeMailingNewName = (event) => {
            setMailingNewName(event.target.value);
        };

        return  <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            // fullWidth
        >
            <DialogTitle><Typography variant="h5" component="div" style={{ fontWeight: 'bold', color: '#4B8B33' }}>
                O mailing já está em uso!
            </Typography></DialogTitle>
            <DialogContent>
                <Box height={80} width="100%">
                <TextField
                    error={errorNomeMailing}
                    helperText={errorNomeMailingMsg} // Corrigido o nome da propriedade aqui
                    id="standard-required"
                    label="Novo nome para o Mailing (Copia)"
                    value={mailingNewName}
                    onChange={handleChangeMailingNewName}
                    fullWidth
                    autoFocus
                />                
            </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCopiar(mailingNewName)} color="default">
                    Copiar Mailing
                </Button>
                <Button onClick={handleManterEspelhado} color="default">
                    Manter espelhado
                </Button>
                <Button onClick={handleClose} color="default">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>

    }

    return (
   
        <>
            <ModalMailingCopia 
                handleCopiar={handleCopiar} 
                handleManterEspelhado={handleManterEspelhado} 
                handleClose={handleClose}
                >
            </ModalMailingCopia>
            <Container fixed>
                <div>
                    <h1> {!id ? "Novo Cliente" : "Editar Cliente" } </h1>
                </div>
                <div  style={{backgroundColor:"#ffff",padding:10}}>
                <form  noValidate autoComplete="off">
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={4}>
                        <ImageUploading
                            multiple={false}
                            value={images}
                            onChange={handleImage}
                            maxNumber={1}
                            dataURLKey="data_url"
                        >
                            {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                            }) => (
                                <div>
                                    {
                                       imageList[0] ? 
                                       <img src={imageList[0]['data_url']} style={{cursor:"pointer"}} onClick={() => onImageUpdate(0)} alt="" width="100" />
                                        :
                                        <img src={blackProfile} style={{cursor:"pointer"}} onClick={onImageUpload} alt="" width="100" />

                                    }
                                </div>
                            )}
                        </ImageUploading>
                        </Grid>
                    </Grid>
                    
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<GreenCheckbox checked={active} onChange={handleActive} name="active" />}
                                label="Ativo"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField required  id="standard-required" error={errorName} helperText={errorNameMsg}   label="Nome fantasia" value={name} onChange={handleName} fullWidth />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField required id="standard-required" error={errorCorporateName} helperText={errorCorporateNameMsg}  label="Razão social" value={corporateName} onChange={handleCorporateName} fullWidth />
                        </Grid>
                        <Grid item xs={2}>
                            <InputMask mask="99.999.999/9999-99" value={cnpj} onChange={handleCnpj}>
                                {(inputProps) => <TextField error={errorCnpj} helperText={errorCnpjMsg} {...inputProps} required  label="CNPJ" />}
                            </InputMask>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={2}>
                            <InputMask mask="99.999.999" value={cep} onChange={handleCep}>
                                {(inputProps) => <TextField {...inputProps}   label="CEP" />}
                            </InputMask>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required id="standard-required" label="Logradouro" error={errorLogradouro} helperText={errorLogradouroMsg} onChange={handleLogradouro} value={logradouro} fullWidth />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField id="standard-required"  label="Número" onChange={handleNumber} value={number} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField  id="standard-required" label="Complemento" onChange={handleComplement} value={complement} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={3}>
                            <TextField required id="standard-required" label="Bairro" error={errorNeighborhood} helperText={errorNeighborhoodMsg} onChange={handleNeighborhood} value={neighborhood} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField required id="standard-required" error={errorCity} helperText={errorCityMsg}  label="Cidade" onChange={handleCity} value={city} fullWidth />
                        </Grid>
                        <Grid item xs={2}>
                            <Autocomplete
                                disablePortal
                                
                                id="state"
                                options={UFS}
                                onChange={(event, newInputValue) => {
                                   handleState(newInputValue);
                                }}
                                value={state}
                                getOptionLabel={(option) => option.nome || ""}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params}  label="Estado" required 
                                error={errorState} helperText={errorStateMsg} 

                                />}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <InputMask mask="(99) 99999-9999" value={telephone} onChange={handleTelephone}>
                                {(inputProps) => <TextField {...inputProps}   label="Telefone" />}
                            </InputMask>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={3}>
                            <Autocomplete
                                disablePortal
                                multiple
                                id="user_responsable"
                                options={users}
                                onChange={(event, newInputValue) => {
                                    handleUserSelected(newInputValue);
                                }}
                                value={usersSelected}
                                getOptionLabel={(option) => option.name || ""}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Assessores responsáveis" />}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                disablePortal
                                multiple
                                id="    "
                                options={mailings}
                                onChange={(event, newInputValue) => {
                                    console.log(event.target.value);
                                    handleMailingSelected(event.target.outerText, newInputValue);
                                }}
                                value={mailingsSelected}
                                getOptionLabel={(option) => option.name || ""}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Mailings" />}
                            />
                        </Grid>
                        <Grid item xs={3}>
                        <TextField 
                            id="standard-required" 
                            label="Segmento" 
                            onChange={handleSegmento} value={segmento} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.root} style={{paddingTop: "30px"}} spacing={2}>
                        <Grid item xs={12}>
                            <label style={{color:"rgba(0, 0, 0, 0.54)",fontSize:"16px"}}>Pessoas de contato</label>
                            <br />
                            <div style={{ height: 450, width: '100%', paddingTop: "10px" }}>
                                <DataGrid
                                    autoPageSize 
                                    pagination 
                                    pageSize={5}
                                    apiRef={apiRef}
                                    rows={contacts} 
                                    columns={columnsContact} 
                                    editMode="row"
                                    editRowsModel={contactsModel}
                                    onEditRowsModelChange={handleEditRowsModelChange}
                                    localeText={ptBR.props.MuiDataGrid.localeText} 
                                    components={{
                                        Toolbar: EditToolbar,
                                    }}
                                    componentsProps={{
                                        toolbar: { apiRef, labelButton:"ADICIONAR CONTATO" },
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.root} style={{paddingTop: "30px"}} spacing={2}>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary"  onClick={handleSave}>
                                SALVAR
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                </div>
                
            </Container>
        </>
    )
}


export  default FormCustomer;