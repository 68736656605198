
import React, { useState,useMemo, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Board from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
import api from '../../services/api';
import {Link, useParams, useHistory} from "react-router-dom";
import { toast  } from 'react-toastify';
import { TextField, Button } from '@material-ui/core/';
import { LinearProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

const GestaoAssessor = (props) => {

    const [emAndamento,setEmAndamento] = useState([])
    const [retornoFuturo,setRetornoFuturo] = useState([])
    const [aguardandoRetorno,setAguardandoRetorno] = useState([])
    const [semInteresse,setSemInteresse] = useState([])
    const [semPerfil,setSemPerfil] = useState([])
    const [blacklist,setBlacList] = useState([])
    const [corporateName,setCorporateName] = useState("")
    const [loading, setLoading] = React.useState(true);
    const [mailings, setMailings] = React.useState([]);
    const [mailingSelected, setMailingSelected] = React.useState(true);
    const [customers, setCustomers] = React.useState([]);
    const [customersSelected, setCustomersSelect] = React.useState(true);
    const [statusSelected, setStatusSelect] = React.useState(true);
    const [cnaeInput, setCnaeInput] = React.useState("");
    const [followUpInput, setFollowUpInput] = React.useState("");
    const [assessores, setAssessores] = useState([]);
    const [assessorSelect, setAssessorSelect] = useState([]);

    const handleCorporateName = (value) => {
      setCorporateName(value)
    };

    
    const [columns,setColumns] = useState({
        columns: [
          {
            id: 8,
            title: 'Em Branco',
            cards: []
          },
          {
            id: 1,
            title: 'Em andamento',
            cards: []
          },
          {
            id: 2,
            title: 'Retorno futuro',
            cards: []
          },
          {
            id: 3,
            title: 'Aguardando retorno',
            cards: []
          },
          {
            id: 4,
            title: 'Sem interesse',
            cards: []
          },
          {
            id: 5,
            title: 'Sem perfil',
            cards: []
          },
          {
            id: 6,
            title: 'Black list',
            cards: []
          },
          {
            id: 7,
            title: 'Reunião agendada',
            cards: []
          }
        ]
      })


    const setAssessor = (mailing_leads_id,newInputValue) => {
      let valores =  [];
      console.log(newInputValue)
      let data = {
        assessor_id: newInputValue?.id,
      };
    
      api.patch("board/"+mailing_leads_id+"/vincular-assessor", data).then(() => {

      }).catch((error) => {
        if(error?.response?.data){
          toast.error(error?.response?.data.message)
        }
      });

      valores = {...assessorSelect,...{[mailing_leads_id]:newInputValue ? newInputValue : ""}}  

     
      if(!newInputValue){
        search()
      }else{
        setAssessorSelect(valores)
      }
     
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            const result = await api.get(`board`,{
              params: { "id_assessor": localStorage.getItem("id_assessor") }
            });
            let data = result.data;
            setAssessorSelect(data["assessores"])
            setColumns(data)
            setLoading(false)
        })()
    },[])

    useEffect(() => {
      (async () => {
          try {
              const result = await api.get("assessores");
              let data = result.data?.data;
              setAssessores(data);
          } catch (e) {
              const response = e.response ? e.response : {};
              const data = response.data ? response.data : {};
              const msg = data.message ? data.message : "Ocorreu um erro ao capturar os dados do mailing";
              toast.error(msg)
          }
      })();
  }, []);

    useEffect(() => {
      (async () => {
          const result = await api.get(`mailings`,{
            params: { 
              "assessor_id":  localStorage.getItem("id_assessor"),
              "customer_id":  customersSelected?.id
            }
          });
          let data = result.data.data;
          console.log(data)
          setMailings(data)
      })()
    },[customersSelected])

    useEffect(() => {
      (async () => {
        const result = await api.get("customers",{ 
          params: { 
            "assessor_id": localStorage.getItem("id_assessor")
          }
        });
        let data = result.data;
        setCustomers(data.data);
      })();
    }, []);

    const search = async() => {
      setLoading(true)
      const result = await api.get(`board`,{
        params: {
          customer_id: customersSelected?.id,
          mailing_id: mailingSelected?.id,
          corporate_name:corporateName,
          mailing_id: mailingSelected?.id,
          status: statusSelected?.status,
          cnae: cnaeInput,
          follow_up: followUpInput,
          id_assessor: localStorage.getItem("id_assessor")
        }
      })

      const data = result?.data
      setColumns(data)
      setLoading(false)
    }

    function changePosition(arr, from, to) {
        arr.splice(to, 0, arr.splice(from, 1)[0]);
        return arr;
    };


  const handleMailingSelected = (value) => {
    setMailingSelected(value);
  };

    const updatePositionAndStatus = async (toColumnId, cards_positions, board,backup_columns) => {
      try {
        let data = {
          status: toColumnId,
          positions: JSON.stringify(cards_positions)
        };
        api.patch("board/"+board.id, data);
      } catch(e) {
        const response = e.response ? e.response : {};
        const data = response.data ? response.data : {};
        const msg = data.message ? data.message : "Ocorreu um erro ao capturar os dados do mailing";
        toast.error(msg)
        setColumns(backup_columns);
        return;
      }
    }

    const onCardDragEnd = async (board,card, source,destination) => {
        //console.log(board)
        //console.log("destination",destination)
        let {toColumnId, toPosition} = source;
        let {fromColumnId} = card;
        let n_coluns = {
          columns: []
        }

        if(toColumnId == fromColumnId) {
          //mudar somente a posição
          n_coluns.columns = columns.columns.map(col => {
            if(col.id == toColumnId) {
              let posicao_atual = col.cards.findIndex(fin => fin.id == board.id);
              col.cards = changePosition(col.cards, posicao_atual, toPosition);
            }
            return col;
          })
          
          let cards_positions = [];
          let indexCol = n_coluns.columns.findIndex(f => f.id == toColumnId);
          for(let index in n_coluns.columns[indexCol].cards) {
            cards_positions.push({
              id_lead: n_coluns.columns[indexCol].cards[index].id,
              position: index
            })
          }

          updatePositionAndStatus(toColumnId, cards_positions, board, columns);

          setColumns(n_coluns);
          return;
        }
        //adicionar o card em push :D
        n_coluns.columns = columns.columns.map(col => {
          if(col.id == toColumnId) {
            col.cards.push(board);
          }
          return col;
        })

        n_coluns.columns = n_coluns.columns.map(col => {
          if(col.id == toColumnId) {
            col.cards = changePosition(col.cards, (col.cards.length - 1), toPosition);
          }
          return col;
        })

        //remover o card com filter :D
        n_coluns.columns = n_coluns.columns.filter(col => {
          if(col.id == fromColumnId) {
            //filtrar os cards
            col.cards = col.cards.filter(cc => {
              if(cc.id != board.id) {
                return cc;
              }
            })
          }
          return col;
        });

        let cards_positions = [];
        let indexCol = n_coluns.columns.findIndex(f => f.id == toColumnId);
        for(let index in n_coluns.columns[indexCol].cards) {
          cards_positions.push({
            id_lead: n_coluns.columns[indexCol].cards[index].id,
            position: index
          })
        }
        
        updatePositionAndStatus(toColumnId, cards_positions, board, columns);
        setColumns(n_coluns);

    }
  


    return (
        <>
            <Container fixed>
                <div>
                    <h1> { "Gestão de Leads" } </h1>
                </div>
                <div
                  style={{
                    borderRadius: "5px",
                    borderLeftWidth: "1px",
                    borderRightWidth: "1px",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    backgroundColor:"#fff",
                    padding:"10px"
                  }}
                >
                  <Grid container  spacing={2}>
                    <Grid item xs={4}>
                      <Autocomplete
                            disablePortal
                            id="Cliente"
                            options={customers}
                            onChange={(event, newInputValue) => {
                              setCustomersSelect(newInputValue);
                            }}
                            value={customersSelected}
                            getOptionLabel={(option) => option.name || ""}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Cliente" />}
                        />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                          disablePortal
                          id="Mailing"
                          options={mailings}
                          onChange={(event, newInputValue) => {
                              handleMailingSelected(newInputValue);
                          }}
                          value={mailingSelected}
                          getOptionLabel={(option) => option.name || ""}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label="Mailing" />}
                      />

                    </Grid>
                    <Grid item xs={4}>
                        <TextField  
                          style={{marginLeft: "5px"}}
                          id="corporate_name"
                          label="Razão Social"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={corporateName} 
                          type={"text"}
                          onChange={
                            evt => handleCorporateName(evt.target.value)
                          } fullWidth/>
                      </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{marginTop: "15px"}}>
                    <Grid container spacing={2} style={{marginTop: "15px"}}>
                      <Grid item xs={4}>
                        <TextField  
                          style={{marginLeft: "5px"}}
                          id="follow_up"
                          label="Follow Up"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={followUpInput} 
                          type={"date"}
                          onChange={
                            evt => setFollowUpInput(evt.target.value)
                          } fullWidth/>
                        </Grid>
                    </Grid>
                  </Grid>
                  <Grid container style={{marginTop:"15px"}}  spacing={2}>
                    <Grid item xs={5}>
                      <Button 
                      variant="contained" 
                      style={{color:"#4B8B33"}} 
                      onClick={() => { search() }}
                      href="#contained-buttons"
                      >
                        PESQUISAR
                      </Button>
                      <Link to="/leads/novo" style={{ textDecoration: 'inherit', marginLeft: "5px"}}>
                          <Button variant="contained" style={{color:"#4B8B33"}} href="#contained-buttons">
                              NOVO LEAD
                          </Button>
                      </Link>
                    </Grid>
                  </Grid>  
                </div>
                <br /><br />
                {loading && <div style={{width: "100%"}}>
                  <LinearProgress/>
                </div>}  

                                  
                <Board 
                    key={"board"}
                    children={columns} 
                    onCardDragEnd={onCardDragEnd}
                    renderCard={({ title, content, id_assessor, assessor_name, lead_id, color_border, follow_up, mailing_leads_id }, { removeCard, dragging }) => (
                  
                        <div style={{backgroundColor: "#fff", borderColor: color_border, borderStyle: "solid",maxWidth:290,width:290, padding: 10, marginBottom: 10, borderRadius: 5}} dragging={dragging} >
                              <Link to={`/leads/${lead_id}/edit?mailing_leads_id=${mailing_leads_id}`} style={{ textDecoration: 'inherit'}} target="_blank">
                                  <b><p>{title}</p></b>
                                </Link>
                            {content}
                            {follow_up && <p><b>Follow-up</b>: {follow_up}</p>}
                            <div>
                            <Autocomplete
                                id={"assessor"+mailing_leads_id}
                                options={assessores}
                                onChange={(event, newInputValue) => {

                                  setAssessor(mailing_leads_id, newInputValue);
                                }}
                                value={id_assessor ? id_assessor : assessorSelect[mailing_leads_id]?.id}
                                inputValue={assessor_name ? assessor_name : (assessorSelect[mailing_leads_id]?.name ? assessorSelect[mailing_leads_id]?.name : "")}
                                getOptionLabel={(option) => option.name || ""}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Assessor" />}
                            />  
                            </div>
                            
                        </div>
                   
                  )}
                />
            </Container>
        </>
    )
}

export default  GestaoAssessor;