import * as axios from 'axios';
import { toast  } from 'react-toastify';

// export const baseURL = 'http://localhost:80/api/'
export const baseURL = 'https://api-crm.prospectbrasil.com.br/api/'

var api = axios.create({
    baseURL: baseURL,
    headers: {
        Authorization : `Bearer ${localStorage.getItem("access_token")}`
    }
});



// api.interceptors.response.use(function (response) {
//     if(response.status == 401) {
//         return <Redirect
//             push 
//             to={{
//                 pathname: "/login"
//              }}
//         />
//     }
//     return response;
//   }, function (error) {
//     if (error.response) {
//         console.log(error.response)
//         if(error.response.status == 401) {
//             toast.error("Não Autorizado");
//             return <Redirect
//             to={{
//                 pathname: "/",
//             }}
//             />
//         }
//     }
//     return Promise.reject(error);
// });

export default api;