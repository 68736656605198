import {useState, useEffect} from "react";
import api from "../services/api";
import { toast  } from 'react-toastify';

export default ({dataLead}) => {
    return (
        <>
            <strong>Razão</strong>: {dataLead.corporate_name}<br/>
            <strong>CNAE/Descrição</strong>: {dataLead.cnae}<br/>
            <strong>CNPJ</strong>: {dataLead.cnpj}<br/>
            <strong>Faturamento</strong>: {dataLead.revenues}<br/>
            <strong>N° de funcionários</strong>: {dataLead.number_employees}<br/>
            <strong>Site</strong>: {dataLead.site}<br/>
            <strong>Endereço</strong>: {dataLead.logradouro}, {dataLead.number} - {dataLead.neighborhood} - {dataLead.city} - {dataLead.state}<br/>
            <strong>Telefone</strong>: {dataLead.telephone}<br/><br/>
            <hr style={{borderStyle:"solid",borderWidth:0.5}} />
        </>
    )
}