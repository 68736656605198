import React, { useState, useEffect } from 'react';

import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const Termo = (props) => {

    const docs = [
        { uri: require("../../assets/politica-privacidade.pdf") }, // Local File
        // { uri: "https://storage.googleapis.com/prospect_brasil_public/politica-privacidade.docx" },
      ];

    return <DocViewer 
        pluginRenderers={DocViewerRenderers}
        documents={docs} 
     />;  

    // return (
    //     <>
    //         <div style={{textAlign:"center"}}> 
    //             <iframe src="https://docs.google.com/gview?url=http://remote.url.tld/path/to/document.doc&embedded=true"></iframe>
    //         </div>
    //     </>
    // )
}

export default Termo;