import React, { useState,useMemo, useEffect } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment"
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
    Box, 
    Button, 
    Typography, 
    Modal, 
    TextField,
    Grid
} from '@material-ui/core';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const styleInput = {
    marginTop: "10px",
    marginBottom: "15px"
}



export default (props) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [responsiblesSelected, setResponsiblesSelected] = useState([]);
    const [terceirizacaoSelected, setTerceirizacaoSelected] = useState([]);

    const [descricao, setDescricao] = React.useState();
    const [data, setData] = React.useState(moment().toDate());
    const [followUp, setFollowUp] = React.useState(null);
    const [databid, setDataBid] = React.useState(null);

    const {salvarResumo} = props;

    const handleButton = () => {
        salvarResumo(descricao, formatarData(data),formatarData(followUp),responsiblesSelected,terceirizacaoSelected,formatarMesAno(databid));
        setOpen(false);
    }
    const formatarData = (data) => {
        if(!data) {
            return null;
        }
        let dia  = data.getDate().toString();
        let diaF = (dia.length == 1) ? '0'+dia : dia
        let mes  = (data.getMonth()+1).toString() //+1 pois no getMonth Janeiro começa com zero.
        let mesF = (mes.length == 1) ? '0'+mes : mes
        let anoF = data.getFullYear();
        return anoF+"-"+mesF+"-"+diaF;
    }

    const formatarMesAno = (data) => {
        if(!data) {
            return null;
        }

        let mes  = (data.getMonth()+1).toString() //+1 pois no getMonth Janeiro começa com zero.
        let mesF = (mes.length == 1) ? '0'+mes : mes
        let anoF = data.getFullYear();
        return mesF + "/" + anoF;
    }

    const responsibles = props.responsibles

    const handleResponsibleSelected = (value) => {
        setResponsiblesSelected(value);
    };


    const handleTerceirizacaoSelected = (value) => {
        setTerceirizacaoSelected(value);
    };

    const options_sim_nao =  [
        {"name": "SIM", "id": "SIM"},
        {"name": "NÃO", "id": "NAO"},
    ]

    return (
        <>
            <div>
            <Button variant="contained" color="primary" onClick={handleOpen} style={{marginBottom: "15px"}}>Adicionar resumo de contato</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid sx={12}>
                        <TextField style={styleInput} multiline required id="standard-required" value={descricao}
                        onChange={(value) => setDescricao(value.target.value)}    
                        label="Descrição" fullWidth />
                    </Grid>
                    <Grid sx={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    
                                    format="dd/MM/yyyy"
                                   
                                    required
                                    fullWidth
                                    id="date-picker-inline"
                                    label="Data"
                                    value={data}
                                    onChange={(value) => setData(value)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid sx={12}>
                        <Autocomplete
                            disablePortal
                            id="responsible"
                            options={responsibles}
                            onChange={(event, newInputValue) => {
                                handleResponsibleSelected(newInputValue);
                            }}
                            value={responsiblesSelected}
                            getOptionLabel={(option) => option.name || ""}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Decisor" />}
                        />
                    </Grid>
                    <Grid sx={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    
                                    format="dd/MM/yyyy"
                                   
                                    fullWidth
                                    id="follow-up"
                                    label="Follow-up"
                                    value={followUp}
                                    onChange={(value) => setFollowUp(value)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid sx={12}>
                        <Autocomplete
                            disablePortal
                            id="terceirizacao"
                            options={options_sim_nao}
                            onChange={(event, newInputValue) => {
                                handleTerceirizacaoSelected(newInputValue);
                            }}
                            value={terceirizacaoSelected}
                            getOptionLabel={(option) => option.name || ""}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Terceirização" />}
                        />
                    </Grid>
                    <Grid sx={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    
                                    format="MM/yyyy"
                                   
                                    fullWidth
                                    id="data_bid"
                                    label="Data de Bid"
                                    value={databid}
                                    onChange={(value) => setDataBid(value)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid sx={12} style={{marginTop: 20}}>
                        <Button color="default" onClick={() => handleButton() }>Adicionar</Button>
                        <Button color="secondanry"  onClick={handleClose}>Cancelar</Button>
                    </Grid>
                </Box>
            </Modal>
            </div>
        </>
    )
}