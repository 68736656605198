import React, { useState,useMemo, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import {TextField, Button} from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import InputMask from 'react-input-mask';
import {useStyles} from "./styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useParams, useHistory} from "react-router-dom";
import { toast  } from 'react-toastify';
import api from '../../../services/api';
import moment from "moment"
import ImageUploading from 'react-images-uploading';
import blackProfile from '../../../assets/imgs/blank-profile.png'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import permissoes_dispo from "../../../services/permissoes";

const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);


 

const FormUser = (props) => {

    const classes = useStyles();

    const history = useHistory()
    let { id } = useParams();
     
    const [active,setActive] = useState(false)
    const [name,setName] = useState("")

    const [cep,setCep] = useState("")
    const [logradouro,setLogradouro] = useState("")
    const [neighborhood,setNeighborhood] = useState("")
    const [number,setNumber] = useState("")
    const [complement,setComplement] = useState("")
    const [city,setCity] = useState("")
    const [state,setState] = useState("")
    const [telephone,setTelephone] = useState("")
    const [ramal,setRamal] = useState("")

    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")


    const [userNameGoTo,setUserNameGoTo] = useState("")
    const [passwordGoTo,setPasswordGoTo] = useState("")
    const [notebook,setNotebook] = useState("")
    const [headfone,setHeadfone] = useState("")
    const [mouse,setMouse] = useState("")
    const [other,setOther] = useState("")
    const [cargo, setCargo] = useState("")
    

    const [errorEmail,setErrorEmail] = useState(false)
    const [errorEmailMsg,setErrorEmailMsg] = useState("")


    const [errorName,setErrorName] = useState(false)
    const [errorNameMsg,setErrorNameMsg] = useState("")

    const [errorPassword,setErrorPassword] = useState(false)
    const [errorPasswordMsg,setErrorPasswordMsg] = useState("")


    const [errorLogradouro,setErrorLogradouro] = useState(false)
    const [errorLogradouroMsg,setErrorLogradouroMsg] = useState("")
    

    const [errorNeighborhood,setErrorNeighborhood] = useState(false)
    const [errorNeighborhoodMsg,setErrorNeighborhoodMsg] = useState("")
    

    const [errorCity,setErrorCity] = useState(false)
    const [errorCityMsg,setErrorCityMsg] = useState("")

    const [errorState,setErrorState] = useState(false)
    const [errorStateMsg,setErrorStateMsg] = useState("")

    const [images, setImages] = React.useState([]);

    const [permissoes, setPermissoes] = useState([]);

    const maxNumber = 69;
  
    const handleImage = (imageList, addUpdateIndex) => {
      setImages(imageList);
    };
  

    const UFS =  [
        {"nome": "", "sigla": ""},
        {"nome": "Acre", "sigla": "AC"},
        {"nome": "Alagoas", "sigla": "AL"},
        {"nome": "Amapá", "sigla": "AP"},
        {"nome": "Amazonas", "sigla": "AM"},
        {"nome": "Bahia", "sigla": "BA"},
        {"nome": "Ceará", "sigla": "CE"},
        {"nome": "Distrito Federal", "sigla": "DF"},
        {"nome": "Espírito Santo", "sigla": "ES"},
        {"nome": "Goiás", "sigla": "GO"},
        {"nome": "Maranhão", "sigla": "MA"},
        {"nome": "Mato Grosso", "sigla": "MT"},
        {"nome": "Mato Grosso do Sul", "sigla": "MS"},
        {"nome": "Minas Gerais", "sigla": "MG"},
        {"nome": "Pará", "sigla": "PA"},
        {"nome": "Paraíba", "sigla": "PB"},
        {"nome": "Paraná", "sigla": "PR"},
        {"nome": "Pernambuco", "sigla": "PE"},
        {"nome": "Piauí", "sigla": "PI"},
        {"nome": "Rio de Janeiro", "sigla": "RJ"},
        {"nome": "Rio Grande do Norte", "sigla": "RN"},
        {"nome": "Rio Grande do Sul", "sigla": "RS"},
        {"nome": "Rondônia", "sigla": "RO"},
        {"nome": "Roraima", "sigla": "RR"},
        {"nome": "Santa Catarina", "sigla": "SC"},
        {"nome": "São Paulo", "sigla": "SP"},
        {"nome": "Sergipe", "sigla": "SE"},
        {"nome": "Tocantins", "sigla": "TO"}
    ];

    const handleActive = (event) => {
        setActive(event.target.checked)
    };


    const handleName = (event) => {
        if(event.target.value){
            setErrorName(false)
            setErrorNameMsg("")
        }
        setName(event.target.value)
    };


    const handleCep = (event) => {
        let cep = event.target.value.replace(/[^0-9]/g, '')
        setCep(cep)
        if(cep.length === 8){
            fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then((res) => res.json())
            .then((data) => {

                if(data.erro && data.erro == true){
                    return false;
                }
    
                setLogradouro(data.logradouro)
                setNeighborhood(data.bairro)
                setCity(data.localidade)
                // console.log(data.uf)
                // setState(data.uf)

                var state = "";
                UFS.map((uf)=>{
                    if(uf.sigla == data.uf){
                        state = uf
                        console.log(state)
                        setState(state)
                    }
                })
    
            }).catch((error) =>{
                alert("Erro")
            });
        }

    };

    const handleLogradouro = (event) => {
        if(event.target.value){
            setErrorLogradouro(false)
            setErrorLogradouroMsg("")
        }

        setLogradouro(event.target.value)
    };

    const handleNumber = (event) => {
        setNumber(event.target.value.replace(/[^0-9]/g, ''))
    };

    const handleRamal = (event) => {
        setRamal(event.target.value.replace(/[^0-9]/g, ''))
    };

    const handleComplement = (event) => {
        setComplement(event.target.value)
    };

    const handleNeighborhood = (event) => {
        if(event.target.value){
            setErrorNeighborhood(false)
            setErrorNeighborhoodMsg("")
        }

        setNeighborhood(event.target.value)
    };

    const handleCity = (event) => {

        if(event.target.value){
            setErrorCity(false)
            setErrorCityMsg("")
        }

        setCity(event.target.value)
    };

    const handleState = (value) => {
        if(value){
            setErrorState(false)
            setErrorStateMsg("")
        }
        setState(value)
    };

    const handleEmail = (event) => {

        if(event.target.value){
            setErrorEmail(false)
            setErrorEmailMsg("")
        }

        setEmail(event.target.value)
    }

    const handleTelephone = (event) => {
        let tel = event.target.value.replace(/[^0-9]/g, '')
        setTelephone(tel)
    }

    const handlePassword = (event) => {
        if(event.target.value){
            setErrorPassword(false)
            setErrorPasswordMsg("")
        }
        setPassword(event.target.value)
    }

    const handleUserNameGoTo = (event) => {
        setUserNameGoTo(event.target.value)
    };

    const handleCargo = (event) => {
        setCargo(event.target.value);
    }

    const handlePasswordGoTo = (event) => {
        setPasswordGoTo(event.target.value)
    };

    const handleNotebook = (event) => {
        setNotebook(event.target.value)
    };

    const handleHeadfone = (event) => {
        setHeadfone(event.target.value)
    };

    
    const handleMouse = (event) => {
        setMouse(event.target.value)
    };
    const handleOther = (event) => {
        setOther(event.target.value)
    };


    const handleCheckboxWrite = (local) => {
        setPermissoes(permissoes.map(permi => {
            if(permi.local === local) {
                permi.write = !permi.write;
            }
            return permi;
        }));
    }

    const handleCheckboxRead = (local) => {
        setPermissoes(permissoes.map(permi => {
            if(permi.local === local) {
                permi.read = !permi.read;
            }
            return permi;
        }));
    }

    const preencherPermissoes = (result = null) => {
        if(result === null) {
            setPermissoes(permissoes_dispo.map(permi => {
                return {
                    "local": permi.local,
                    "titulo": permi.titulo,
                    "read": false,
                    "write": false
                }
            }));
        } else {
            setPermissoes(permissoes_dispo.map(permi => {
                let permi_existente = null;
                result.map(permiExi => {
                    if(permiExi.local === permi.local) {
                        permi_existente = permiExi;
                    }
                })
                if(permi_existente != null) {
                    console.log(permi_existente);
                    return {
                        "local": permi.local,
                        "titulo": permi.titulo,
                        "read": permi_existente.read,
                        "write": permi_existente.write
                    }
                } else {
                    return {
                        "local": permi.local,
                        "titulo": permi.titulo,
                        "read": false,
                        "write": false
                    }
                }
            }));
        }
    }
    useEffect(() => {
        (async () => {
            try {
                if(id) {
                    const result = await api.get(`assessor/${id}`)
                    const data = result?.data
                    preencherPermissoes(data.permissoes);
                    setActive(data.active)
                    setName(data.name)
                    setEmail(data.email)
                    setCep(data.zip_code)
                    setLogradouro(data.logradouro)
                    setNeighborhood(data.neighborhood)
                    setComplement(data.complement)
                    setCity(data.city)
                    setTelephone(data.telephone)
                    setUserNameGoTo(data.username_goto)
                    setPasswordGoTo(data.password_goto)
                    setNotebook(data.notebook)
                    setHeadfone(data.headfone)
                    setMouse(data.mouse)
                    setOther(data.other)
                    setRamal(data.ramal)
                    setCargo(data.cargo)
                    if(data.image){
                        setImages([{"data_url":data.image}])
                    }

                    var state = "";
                    UFS.map((uf)=>{
                        if(uf.sigla == data.state){
                            state = uf
                            console.log(state)
                            setState(state)
                        }
                    })
            
                   
                    setNumber(data.number)
                } else {
                    preencherPermissoes();
                }

            }catch(e){
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.message ? data.message : "Ocorreu um erro ao salvar o usuário";
                console.log(e);
                toast.error(msg)
               
            }
          
          })()
    },[])

    const validationFields = async () => {   
        let result = true
        if(!name){
            setErrorName(true)
            setErrorNameMsg("Campo Obrigátorio")
            result = false
        }

        if(!email){
            setErrorEmail(true)
            setErrorEmailMsg("Campo Obrigátorio")
            result = false
        }

        if(!password && !id){
            setErrorPassword(true)
            setErrorPasswordMsg("Campo Obrigátorio")
            result = false
        }

        if(!logradouro){
            setErrorLogradouro(true)
            setErrorLogradouroMsg("Campo Obrigátorio")
            result = false
        }

        if(!neighborhood){
            setErrorNeighborhood(true)
            setErrorNeighborhoodMsg("Campo Obrigátorio")
            result = false
        }

        if(!city){
            setErrorCity(true)
            setErrorCityMsg("Campo Obrigátorio")
            result = false
        }
 
        if(!state){
            setErrorState(true)
            setErrorStateMsg("Campo Obrigátorio")
            result = false
        }

        return result
    }

    const clearErrorsFields = () => {  

        setErrorName(false)
        setErrorNameMsg("")

        setErrorEmail(false)
        setErrorEmailMsg("")

        setErrorPassword(false)
        setErrorPasswordMsg("")

        setErrorLogradouro(false)
        setErrorLogradouroMsg("")

        setErrorPassword(false)
        setErrorLogradouroMsg("")

        setErrorNeighborhood(false)
        setErrorNeighborhoodMsg("")

        setErrorCity(false)
        setErrorCityMsg("")

        setErrorState(false)
        setErrorStateMsg("")
    }


    const handleSave = async () => {   
        let validation = await validationFields()
        if(validation){
            clearErrorsFields();
            let data = {
                active,
                image:images.length > 0 ? images[0]["data_url"] : null,
                email,
                name,
                zip_code: cep,
                logradouro,
                number,
                complement,
                neighborhood,
                city,
                state: state.sigla,
                telephone,
                password,
                username_goto:userNameGoTo,
                password_goto:passwordGoTo,
                notebook,
                headfone,
                mouse,
                other,
                ramal,
                cargo,
                permissions: permissoes
            };
     
            console.log(data)
            let config = {
                headers: {
                  "Content-Type": "application/json"
                  }
            }
              

            try {
                if(id){
                    await api.post(`/assessor/${id}/save`,data,config)
                    toast.success("Usuário editado com sucesso")
                    
                } else {
                    const result = await api.post("/assessor/save",data)
                    toast.success("Usuário cadatrado com sucesso")
                    const id_created = (result.data?.data?.id)
                    history.replace(`/user/${id_created}/edit`)
                }
              
            }catch(e){
                const response = e.response ? e.response : {};
                const data = response.data ? response.data : {};
                const msg = data.message ? data.message : "Ocorreu um erro ao salvar o usuário";
                toast.error(msg)
            }
        }
        

    };

    return (
   
        <>
         
            <Container fixed>
                <div>
                    <h1> {!id ? "Novo Usuário" : "Editar Usuário" } </h1>
                </div>

                <div  style={{backgroundColor:"#ffff",padding:10}}>
                <form  noValidate autoComplete="off">
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={4}>
                        <ImageUploading
                            multiple={false}
                            value={images}
                            onChange={handleImage}
                            maxNumber={1}
                            dataURLKey="data_url"
                        >
                            {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                            }) => (
                                <div>
                                    {
                                       imageList[0] ? 
                                       <img src={imageList[0]['data_url']} style={{cursor:"pointer"}} onClick={() => onImageUpdate(0)} alt="" width="100" />
                                        :
                                        <img src={blackProfile} style={{cursor:"pointer"}} onClick={onImageUpload} alt="" width="100" />

                                    }
                                </div>
                            )}
                        </ImageUploading>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<GreenCheckbox checked={active} onChange={handleActive} name="active" />}
                                label="Ativo"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField required id="standard-required"  label="Nome" error={errorName} helperText={errorNameMsg} value={name} onChange={handleName} fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField required id="standard-required"  label="E-mail" error={errorEmail} helperText={errorEmailMsg} value={email} onChange={handleEmail} fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField required={!id ? true : false} id="standard-required"  label="Senha" error={errorPassword} helperText={errorPasswordMsg} type={"password"}  value={password} onChange={handlePassword} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={2}>
                            <InputMask mask="99.999.999" value={cep} onChange={handleCep}>
                                {(inputProps) => <TextField {...inputProps}   label="CEP" />}
                            </InputMask>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required id="standard-required" label="Logradouro" error={errorLogradouro} helperText={errorLogradouroMsg} onChange={handleLogradouro} value={logradouro} fullWidth />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField  id="standard-required"  label="Número"  onChange={handleNumber} value={number} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField  id="standard-required" label="Complemento" onChange={handleComplement} value={complement} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={3}>
                            <TextField required id="standard-required" label="Bairro" error={errorNeighborhood} helperText={errorNeighborhoodMsg} onChange={handleNeighborhood} value={neighborhood} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField required id="standard-required" label="Cidade" error={errorCity} helperText={errorCityMsg} onChange={handleCity} value={city} fullWidth />
                        </Grid>
                        <Grid item xs={2}>
                       
                            <Autocomplete
                                disablePortal
                                
                                id="state"
                                options={UFS}
                                onChange={(event, newInputValue) => {
                                   handleState(newInputValue);
                                }}
                                value={state}
                                getOptionLabel={(option) => option.nome || ""}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params}  label="Estado" 
                                error={errorState} helperText={errorStateMsg} 
                                />
                                
                            }
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <InputMask mask="(99) 9999-9999" value={telephone} onChange={handleTelephone}>
                                {(inputProps) => <TextField {...inputProps}   label="Telefone" />}
                            </InputMask>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField  id="standard-required"  label="Ramal"  onChange={handleRamal} value={ramal} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={3}>
                            <TextField  id="standard-required" label="Username GoTo" onChange={handleUserNameGoTo} value={userNameGoTo} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField  id="standard-required" label="Password GoTo" onChange={handlePasswordGoTo} value={passwordGoTo} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField  id="standard-required"  label="Cargo" value={cargo} onChange={handleCargo} fullWidth />
                        </Grid>
                    </Grid>
                    <h4 style={{color:"#726e6e"}}>Equipamentos</h4>
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={3}>
                            <TextField  id="standard-required" label="Notebook" onChange={handleNotebook} value={notebook} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField  id="standard-required" label="Headfone" onChange={handleHeadfone} value={headfone} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField  id="standard-required" label="Mouse" onChange={handleMouse} value={mouse} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField  id="standard-required" label="Outros" onChange={handleOther} value={other} fullWidth />
                        </Grid>
                    </Grid>
                    
                    <Grid container className={classes.root} style={{paddingTop: "30px"}}>
                        <Grid item xs={12}>
                            <h4 style={{color:"#726e6e"}}>Permissões</h4>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minHeight: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Local</TableCell>
                                            <TableCell align="center">Visualizar</TableCell>
                                            <TableCell align="center">Criar/Editar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {permissoes.map((permi, indice) => (
                                            <TableRow key={indice}>
                                                <TableCell align="center">{permi.titulo}</TableCell>
                                                <TableCell align="center">
                                                    <Checkbox checked={permi.read} onClick={() => handleCheckboxRead(permi.local)} />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Checkbox checked={permi.write} onClick={() => handleCheckboxWrite(permi.local)}/>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.root} style={{paddingTop: "30px"}} spacing={2}>
                    
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary"  onClick={handleSave}>
                                SALVAR
                            </Button>
                        </Grid>
                     
                    </Grid>
                </form>
                </div>
            </Container>
        </>
    )
}


export  default FormUser;