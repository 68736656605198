import Login from '../containers/Login'

import ListingUser from '../containers/users/ListingUser'
import FormUser from '../containers/users/FormUser'

import ListingCustomer from '../containers/customers/ListingCustomer'
import FormCustomer from '../containers/customers/FormCustomer'

import ListingMailing from '../containers/mailing/ListingMailing'
import FormMailing from '../containers/mailing/FormMailing'

import FormLead from '../containers/leads/FormLead'
import ImportacaoLead from '../containers/leads/Importacao/'


import GestaoAssessor from '../containers/GestaoAssessor'
import AgendamentoLead from "../containers/agendamentoLeads/index.jsx";
import FormAgendamentoLead from '../containers/agendamentoLeads/Form.jsx'
import Termo from '../containers/Termo'

import Relatorio from '../containers/Relatorio'
import Kpi from '../containers/Kpi'


import LeadsList from "../containers/leads/IndexLead/index";


import Home from "../containers/Home";
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import InsertInvitationOutlinedIcon from '@material-ui/icons/InsertInvitationOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';

const paths =  [
    {
        "to":"/",
        "container":Login,
        "text":"Login",
        "viewMenu":false
    },
    {
        "to":"/login",
        "container":Login,
        "text":"Login",
        "viewMenu":false
    },
    {
        "to":"/home",
        "container":Home,
        "text":"Home",
        "viewMenu":false
    },
    {
        "to":"/users",
        "icon":<AccountBoxOutlinedIcon/>,
        "container":ListingUser,
        "local":"usuarios",
        "permissao":"read",
        "text":"Usuários",
        "viewMenu":true
    },
    {
        "to":"/user/new",
        "container":FormUser,
        "local":"usuarios",
        "permissao":"write",
        "text":"Novo Usuário",
        "viewMenu":false
    },
    {
        "to":"/user/:id/edit",
        "container":FormUser,
        "local":"usuarios",
        "permissao":"write",
        "text":"Editar Cliente",
        "viewMenu":false
    },
    {
        "to":"/customer/new",
        "container":FormCustomer,
        "permissao":"write",
        "local":"clientes",
        "text":"Novo Cliente",
        "viewMenu":false
    },
    {
        "to":"/customer/:id/edit",
        "container":FormCustomer,
        "permissao":"write",
        "local":"clientes",
        "text":"Editar Cliente",
        "viewMenu":false
    },
    {
        "to":"/customers",
        "icon":<PeopleOutlinedIcon/>,
        "container":ListingCustomer,
        "permissao":"read",
        "local":"clientes",
        "text":"Clientes",
        "viewMenu":true
    },
    {
        "to":"/mailing",
        "icon":<FolderOpenOutlinedIcon/>,
        "container":ListingMailing,
        "permissao":"read",
        "local":"mailing",
        "text":"Mailing",
        "viewMenu":true
    },
    {
        "to": "/leads",
        "container": LeadsList,
        "icon":<ListAltOutlinedIcon/>,
        "permissao":"read",
        "text": "Leads",
        "local":"leads",
        "viewMenu": true
    },
    {
        "to": "/leads/novo",
        "container":  FormLead,
        "permissao":"write",
        "local":"leads",
        "viewMenu": false
    },
    {
        "to": "/leads/importacao",
        "container":  ImportacaoLead,
        "icon":<ListAltOutlinedIcon/>,
        "permissao":"read",
        "text": "Importação Lead",
        "local":"leads",
        "viewMenu": false
    },
    {
        "to": "/leads/:id/edit",
        "container": FormLead,
        "permissao":"write",
        "local":"leads",
        "viewMenu": false
    },
    {
        "to":"/mailing/new",
        "container":FormMailing,
        "permissao":"write",
        "local":"mailing",
        "text":"Novo Mailing",
        "viewMenu":false
    },
    {
        "to":"/mailing/:id/edit",
        "container":FormMailing,
        "permissao":"write",
        "local":"mailing",
        "text":"Novo Mailing",
        "viewMenu":false
    },
    {
        "to":"/mailing/:id_mailing/lead/new",
        "container":FormLead,
        "text":"Novo Lead",
        "permissao":"write",
        "local":"leads",
        "viewMenu":false
    },
    {
        "to":"/mailing/:id_mailing/lead/:id/edit",
        "container":FormLead,
        "permissao":"write",
        "text":"Novo Lead",
        "local":"leads",
        "viewMenu":false
    },
    {
        "to":"/gestao-assessor",
        "icon":<AssignmentOutlinedIcon/>,
        "container": GestaoAssessor,
        "text": "Gestão do Assessor",
        "permissao":"read",
        "local":"leads",
        "viewMenu": true
    },
    {
        "to": "/agendamento-lead",
        "container": AgendamentoLead,
        "icon":<InsertInvitationOutlinedIcon/>,
        "permissao":"read",
        "text": "Reuniões agendadas",
        "local":"leads",
        "viewMenu": true
    }, {
        "to": "/agendamento-lead/novo/:id_lead?", 
        "container": FormAgendamentoLead,
        "viewMenu": false,
        "permissao":"read",
        "text": "Agendamento de Leads",
    },
    {
        "to": "/agendamento-lead/:id", 
        "container": FormAgendamentoLead,
        "viewMenu": false,
        "permissao":"read",
        "text": "Visualizar Agendamento de Leads",
    },
    {
        "to": "/politica", 
        "container": Termo,
        "viewMenu": false,
        "permissao":"write",
    },
    {
        "to": "/relatorio", 
        "container": Relatorio,
        "icon":<ListAltOutlinedIcon/>,
        "viewMenu": true,
        "permissao":"write",
        "local":"leads",
        "text": "Relatório",
    },
    {
        "to": "/kpi", 
        "container": Kpi,
        "icon":<ListAltOutlinedIcon/>,
        "viewMenu": true,
        "permissao":"write",
        "local":"kpi",
        "text": "KPIs",
    }
]

export default paths;