import React, {useEffect, useState} from 'react';
import "./styles.css";
import {TextField, Button} from '@material-ui/core/';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Logo from '../../assets/imgs/prospect_brasil.png'
import LogoBora from '../../assets/imgs/bora.png'
import {Link,Redirect} from 'react-router-dom';
import api from '../../services/api';
import { toast  } from 'react-toastify';

const Login = (props) => {


    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [redirect,setRedirect] = useState(false)
    const [loading, setLoading] = React.useState(false);

    
    const handleEmail= (event) => {
        setEmail(event.target.value)
    };


    const handlePassword = (event) => {
        setPassword(event.target.value)
    };

 
    const handleLogin = async ()   => {  
        

        if(!email) {
            toast.error("Informe seu e-mail");
            return 
        }

        if(!password) {
            toast.error("Senha obrigátoria");
            return
        }

        setLoading(true);
        
        let login = "";

         await api.post("login", {
            email,
            password
        }).then(function(response){
            login = response.data
            setLoading(false);
            localStorage.setItem("access_token",login.access_token);
            localStorage.setItem("name",login.user.name);
            localStorage.setItem("id_assessor",login.user.id_assessor);

            let permissoes = login.user.permissoes
            for(let key in permissoes) {
                localStorage.setItem(permissoes[key].local,JSON.stringify(permissoes[key]));
            }
            setRedirect(true)
            toast.success("Login realizado");
        })
        .catch(function (error) {
            localStorage.setItem("access_token","");
            setLoading(false);
            if (error.response) {
                console.log(error.response)
                if(error.response.status == 401)
                    toast.error("Não Autorizado");
            }
        })

        console.log("login",login)
      

    };

    if(redirect) {
        var host = window.location.protocol + "//" + window.location.host;
        window.location.href = `${host}/home`;
        // return <Redirect
        // to={{
        //     pathname: "/home",
        // }}
        // />
    }


    return (
    <div class="bodylogin">
   
        <form className="form_login">
            <div className="logo-form-login" >
                <img src={Logo} width={200}></img>
            </div>
            
            <Grid item spacing={4} style={{paddingTop: "30px"}}>   
                <TextField required id="standard-required" label="E-mail" onChange={handleEmail} value={email} fullWidth />
            </Grid>
            <Grid item spacing={4} style={{paddingTop: "30px"}}>   
                <TextField required id="standard-required" type="password"  label="Senha" onChange={handlePassword} value={password} fullWidth />
            </Grid>
            <Grid item spacing={4} style={{paddingTop: "30px",display:"flex",alignItems:"center",justifyContent:"center"}}>   

                    <Button variant="contained" color="primary" disabled={loading}  onClick={handleLogin}>
                        ENTRAR
                    </Button>
              
                  
            </Grid>
            <Link to="/" style={{ textDecoration: 'inherit'}}>
                <Grid item spacing={4} style={{marginBottom:"10px",paddingTop: "30px",display:"flex",alignItems:"center",justifyContent:"center"}}>   
                    <div>Esquecia a senha</div>
                </Grid>
            </Link>
        </form>
        <div className="logo-bora" >
            <img src={LogoBora} width={200}></img>
        </div>
    </div>
  )
   
}

export default Login;