import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import ImportacaoLeads from './ImportacaoLead';
import ImportacaoContatos from './ImportacaoContatos';

const Importacao = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <h2>Importação</h2>
      </div>
      <Tabs value={tabIndex}  indicatorColor="primary" onChange={handleTabChange} centered>
        <Tab label="Importação de Leads" />
        <Tab label="Importação de Contatos" />
      </Tabs>
      <Box p={3}>
        {tabIndex === 0 && <ImportacaoLeads />}
        {tabIndex === 1 && <ImportacaoContatos />}
      </Box>
    </>
  );
};

export default Importacao;
