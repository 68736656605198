import {useState, useEffect} from "react";
import {
    Container, 
    Button, 
    Table, 
    TableHead, 
    TableBody, 
    TableFooter,
    Grid,
    Modal,
    TextField,
    Select,
    MenuItem,
    InputLabel
} from "@material-ui/core";

import { DataGrid,
    ptBR
} from '@mui/x-data-grid';

import Pagination from '@material-ui/lab/Pagination';
import { toast  } from 'react-toastify';
import Olho from "@material-ui/icons/RemoveRedEye"
import api, { baseURL } from "../../services/api";
import {StyledTableCell,StyledTableRow, useStyles} from '../mailing/ListingMailing/styles';
import {Link} from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LinearProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as axios from 'axios';

const columns = [
    {
        field: 'actions',
        headerName: 'Ações',
        renderCell: RowMenuCell,
        sortable: false,
        width: 100,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true,
    },
    { 
        field: 'numero_reuniao', 
        headerName: 'Número Reunião', 
        width: 250,
        filterable: false,
        disableReorder: true,
        sortable: false,

    },
    { 
        field: 'data_reuniao', 
        headerName: 'Data Reunião', 
        width: 250,
        type: "date",
        disableReorder: true,
        sortable: false,

    },
    { 
        field: 'hora_reuniao', 
        headerName: 'Hora Reunião', 
        width: 250,
        disableReorder: true,
        sortable: false,
    },
    { 
        field: 'customersName', 
        headerName: 'Cliente', 
        filterable: false,
        width: 250,
        disableReorder: true,
        sortable: false,

    },
    { 
        field: 'corporate_name', 
        headerName: 'Razão Social', 
        filterable: false,
        width: 250,
        disableReorder: true,
        sortable: false,

    },
    { 
        field: 'cargo', 
        headerName: 'Cargo do contato', 
        filterable: false,
        width: 250,
        disableReorder: true,
        sortable: false,

    },
    
    { 
        field: 'cnae', 
        headerName: 'CNAE', 
        filterable: false,
        width: 250,
        disableReorder: true,
        sortable: false,

    },
    { 
        field: 'data_agendamento', 
        headerName: 'Data Agenda', 
        width: 250,
        type: "date",
        disableReorder: true,
        sortable: false,


    },
    { 
        field: 'status_auditoria', 
        headerName: 'Status Auditoria', 
        width: 250,
        filterable: false,
        disableReorder: true,
        sortable: false,

    },
    { 
        field: 'status', 
        headerName: 'Status Agendamento', 
        width: 250,
        disableReorder: true,
        sortable: false,

    },
    { 
        field: 'enviou_briefing', 
        headerName: 'Status Briefing', 
        width: 250,
        filterable: false,
        disableReorder: true,
        sortable: false,

    },
    { 
        field: 'assessoresName', 
        headerName: 'Assessor', 
        width: 250,
        disableReorder: true,
        sortable: false,

    }
    
];



function RowMenuCell(props) {
    const { api : apiTable, id, row} = props;
    const classes = useStyles();
    const [confirmOpen,setConfirmOpen] = useState(false);

    const handleDeleteClick = (event) => {
        // event.stopPropagation();

        api.delete(`agendamento-lead/${id}`).then(res => {
            console.log(res)
            apiTable.updateRows([{ id, _action: 'delete' }]);
        }).catch(error => {
            console.log(error)
        })

        
    };
    
      
    return (
      <div className={classes.root}>
         <Link to={"/agendamento-lead/"+row.idAgendamento+"?id_lead="+row.lead_id}>
            <IconButton>
                <EditIcon/>
            </IconButton>
        </Link>
        <IconButton
          color="inherit"
          size="small"
          aria-label="delete"
          onClick={() => setConfirmOpen(true)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
        <ConfirmDialog
            title="Excluir agenda?"
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={handleDeleteClick}
        >
           Tem certeza de que deseja excluir?
        </ConfirmDialog>
      </div>
    );
}


const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color="secondary"
        >
          Não
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="default"
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AgendamentoLead = () => {
    const [rows, setRows] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [customers, setCustomers] = useState([]);

    const [dataReuniao, setDataReuniao] = useState("");
    const [cliente, setCliente] = useState();
    const [dataAgendamento, setDataAgendamento] = useState("");
    const [statusAuditoria, setStatusAuditoria] = useState();
    const [statusConfirmacao, setStatusConfirmacao] = useState();
    const [statusBriefing, setStatusBriefing] = useState();
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(50);
    const [filters, setFilters] = useState([]);
    const [permissisao,setPermissisao] = useState(JSON.parse(localStorage.getItem("usuarios")));

    useEffect(() => {
        getAgendamentos();
        //clientes
        (async () => {
            let data = await api.get("customers", {
                params: {
                    pageSize: 99999,
                }
            });
            setCustomers(data?.data.data);
        })();

        
    }, []);


    useEffect(() => {
        getAgendamentos();
    }, [page,filters]);

    function handlePage(value) {
        setPage(value);
    }

    async function getAgendamentos() {
        try {
            setLoading(true)

            let data = await api.get(`agendamento-lead/listing`, {
                params: {
                    page: page +1,
                    data_reuniao: dataReuniao,
                    pageSize: 50,
                    cliente: cliente?.id,
                    data_agendamento: dataAgendamento,
                    status_auditoria: statusAuditoria,
                    status_confirmacao: statusConfirmacao,
                    status_confirmacao: statusConfirmacao,
                    enviou_briefing: statusBriefing,
                    id_assessor: localStorage.getItem("id_assessor"),
                    filters:filters
                }
            });
            setLoading(false)
            setRows(data.data.data);
            const totalPerPage = data.data.per_page;
            setTotal(data.data.total);
            setPageSize(data.data.per_page);
        } catch(exception) {
            toast.error(exception.message);
        }
    }

    const onFilterChange = (filterModel) => {
        console.log(filterModel)
        setFilters(filterModel.items)
    };


    const exportAgenda = async () => {
        setLoading(true)
        
        await axios({
            url: `${baseURL}agenda-export`, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
        
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'agendas.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);

            setLoading(false)

        }).catch( () =>{
            setLoading(false)
        });

     

    }

    return (
        <Container>
            <h1>Agendamento de Leads</h1>
            <div
                  style={{
                    borderRadius: "5px",
                    borderLeftWidth: "1px",
                    borderRightWidth: "1px",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    backgroundColor:"#fff",
                    padding:"10px",
                    paddingBottom:"10px",
                    marginBottom: "20px"
                  }}
                >
                <Container>
                  <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField  
                                id="standard-required" 
                                label="Data da reunião" 
                                fullWidth 
                                type={"date"}
                                InputLabelProps={{ shrink: true, required: true }}
                                value={dataReuniao}
                                onChange={evt => setDataReuniao(evt.target.value)}
                                />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                            disablePortal
                            id="Clientes"
                            options={customers}
                            onChange={(event, newInputValue) => {
                                setCliente(newInputValue);
                            }}
                            value={cliente}
                            getOptionLabel={(option) => option.name || ""}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Cliente" />}
                        />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField  
                                id="standard-required" 
                                label="Data do agendamento" 
                                fullWidth 
                                type={"date"}
                                InputLabelProps={{ shrink: true, required: true }}
                                value={dataAgendamento}
                                onChange={evt => setDataAgendamento(evt.target.value)}
                                />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel>Status Auditoria</InputLabel>
                            <Select
                                labelId="Status Auditoria"
                                id="status_auditoria"
                                value={statusAuditoria}
                                onChange={evt => setStatusAuditoria(evt.target.value)}
                            >
                                <MenuItem value={null}></MenuItem>
                                <MenuItem value={"em_branco"}>Em Branco</MenuItem>
                                <MenuItem value={"2"}>Aguardando</MenuItem>
                                <MenuItem value={"1"}>Aprovada</MenuItem>
                                <MenuItem value={"3"}>Não Aprovado</MenuItem>

                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel>Status Confirmação</InputLabel>
                            <Select
                                labelId="Status Confirmação"
                                id="status_confirmacao"
                                value={statusConfirmacao}
                                onChange={evt => setStatusConfirmacao(evt.target.value)}
                            >
                                <MenuItem value={null}></MenuItem>
                                <MenuItem value="em_branco">Em Branco</MenuItem>
                                <MenuItem value="aguardando">Aguardando</MenuItem>
                                <MenuItem value="confirmado">Confirmada</MenuItem>
                                <MenuItem value="realizada">Realizada</MenuItem>
                                <MenuItem value="reagendada">Reagendada</MenuItem>
                                <MenuItem value="declinada">Declinada</MenuItem>
                                <MenuItem value="aguardando_nova_data">Aguardando nova data</MenuItem>
                                <MenuItem value="nao_aprovada">Não aprovada</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel>Status Briefing</InputLabel>
                            <Select
                                labelId="Status Briefing"
                                id="enviou_briefing"
                                value={statusBriefing}
                                onChange={evt => setStatusBriefing(evt.target.value)}
                            >
                                <MenuItem value={null}></MenuItem>
                                <MenuItem value={"em_branco"}>Em Branco</MenuItem>
                                <MenuItem value={"0"}>Aguardando</MenuItem>
                                <MenuItem value={"1"}>Enviado</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={getAgendamentos} variant="contained" style={{color:"#4B8B33"}}>
                                PESQUISAR
                            </Button>
                        </Grid>
                  </Grid>
                </Container>
            </div>
            <div style={{marginBottom: "10px"}}>
                <Link to={"/agendamento-lead/novo"} style={{ textDecoration: 'inherit'}}>
                    <Button variant="contained" style={{color:"#4B8B33"}} href="#contained-buttons">
                        NOVO
                    </Button>
      
                </Link>
                {
                     permissisao.write ?   <Button variant="contained" style={{color:"#4B8B33", marginLeft: "10px"}} onClick={() => exportAgenda()}>
                     EXPORTAR
                    </Button> : ""
                }
              
            </div>
            {loading && <div style={{width: "100%"}}>
                <LinearProgress/>
            </div>}
            
            <div style={{ height: 600, width: '100%', paddingTop: "10px" }}>

                <DataGrid
                    pagination
                    rowCount={total}
                    style={{paddingTop:20}}
                    rows={rows} 
                    page={page}
                    pageSize={pageSize}
                    loading={loading}
                    columns={columns} 
                    rowsPerPageOptions={[pageSize]}
                    localeText={ptBR.props.MuiDataGrid.localeText}
                    paginationMode="server"
                    filterMode="server"
                    onPageChange={handlePage}
                    onFilterModelChange={onFilterChange}
                />   
            </div>

            {/* <Table>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell>Data da Reunião</StyledTableCell>
                        <StyledTableCell>Cliente</StyledTableCell>
                        <StyledTableCell>Data da Agendamento</StyledTableCell>
                        <StyledTableCell>Status (auditoria)</StyledTableCell>
                        <StyledTableCell>Status (confirmação)</StyledTableCell>
                        <StyledTableCell>Ações</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <StyledTableRow key={row.idAgendamento}>
                            <StyledTableCell>{row.data_reuniao}</StyledTableCell>
                            <StyledTableCell>{row.customersName}</StyledTableCell>
                            <StyledTableCell>{row.data_agendamento}</StyledTableCell>
                            <StyledTableCell>{row.status_auditoria ? row.status_auditoria : "Em Branco"}</StyledTableCell>                        
                            <StyledTableCell>{row.statusAgendamento ? row.statusAgendamento : "Em Branco"}</StyledTableCell>    
                            <StyledTableCell>
                                <Link to={"/agendamento-lead/"+row.idAgendamento+"?id_lead="+row.lead_id}>
                                    <Button variant={"contained"} color={"default"}>
                                        <Olho/>
                                    </Button>
                                </Link>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
                <TableFooter>
                <div style={{textAlign:"center",paddingTop:"10px",paddingBottom:"10px"}}>
                    <Pagination style={{textAlign:"center"}} count={total} page={page} onChange={handlePage} />
                </div>
                </TableFooter>
            </Table> */}
        </Container>
    )
}

export default AgendamentoLead;