import React, { useState, useEffect } from 'react';

import * as axios from 'axios';


const Home = (props) => {
    const [name,setName] = useState("")


    useEffect(() => {
        setName(localStorage.getItem("name"));

        var api = axios.create({
            baseURL: 'https://conteudodoc.azurewebsites.net',
            headers: {
            }
        });
         api.get("/wp-json/wp/v2/produtos")  .then( (res) =>{
            console.log(res)
         })
    },[])

    return (
        <>
            <div style={{textAlign:"center"}}> 
                <h2>Bem-vindo {name}!</h2>
            </div>
        </>
    )
}

export default Home;
